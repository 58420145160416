import React, { FC, useContext, useEffect } from 'react';
import { ExperimentsContext } from '../context/ExperimentsContext';
import { isBrowser } from '../utils';

export interface ExperimentsWrapperProps {
    children: any;
    data: any; // TODO - does gatsby have a type for this??
}

export const ExperimentsWrapper: FC<ExperimentsWrapperProps> = ({
    children,
    data,
}) => {
    const { setExperiments } = useContext(ExperimentsContext);

    // Handle route change

    useEffect(() => {
        const waitForGlobal = function (key, callback) {
            if (window[key]) {
                callback();
            } else {
                setTimeout(() => {
                    waitForGlobal(key, callback);
                }, 100);
            }
        };

        if (isBrowser) {
            waitForGlobal('experiments', () => {
                setExperiments(window.experiments);
            });
        }
    }, [data]);

    return <>{children}</>;
};
