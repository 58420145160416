import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const StyleMain = styled.main`
    ${({ theme }) => css`
        > .band:first-of-type {
            padding-top: 0; // Bit of a temp hack - will come back to this
        }
    `}
`;

export const Main = ({ children }) => (
    <StyleMain
        role="main"
        id="main-content"
        className="main"
        data-ga-scroll="50,75"
    >
        {children}
    </StyleMain>
);
