import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface SpacerProps {
    spacing?: 'half' | 'quarter';
    responsive?: boolean;
    as?: any;
}

const SpacerEl = styled.div<SpacerProps>`
    ${({ theme: { space, media }, spacing, responsive }) => {
        let childSpacing;
        let ChildSpacingDesktop;

        switch (spacing) {
            case 'quarter':
                childSpacing = space.sm;
                ChildSpacingDesktop = space.md;
                break;
            case 'half':
                childSpacing = space.md;
                ChildSpacingDesktop = space.lg;
                break;
            default:
                childSpacing = space.lg;
                ChildSpacingDesktop = space.xl;
        }

        return css`
            > * + * {
                margin-top: ${childSpacing};
            }

            // Responsive (adjust spacing for desktop)

            ${responsive &&
            css`
                @media (min-width: ${media.md}) {
                    > * + * {
                        margin-top: ${ChildSpacingDesktop};
                    }
                }
            `}
        `;
    }}
`;

export const Spacer: FC<React.PropsWithChildren<SpacerProps>> = ({
    children,
    spacing,
    as,
    responsive,
    ...rest
}) => (
    <SpacerEl spacing={spacing} as={as} responsive={responsive} {...rest}>
        {children}
    </SpacerEl>
);

export default Spacer;
