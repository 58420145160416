import React, { FC } from 'react';

export const FeLogoEggsBlack: FC = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="31" viewBox="0 0 40 31" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#000"
                d="M29.322 27.167c-2.573 0-4.658-1.714-4.658-3.723s2.085-3.723 4.658-3.723c2.57 0 5.506 1.714 5.506 3.723s-2.936 3.723-5.506 3.723M28.895 16C23.756 16 20 19.423 20 23.444c0 4.017 3.756 7.441 8.895 7.441 5.138 0 10.846-3.424 10.846-7.441 0-4.02-5.708-7.444-10.846-7.444"
            />
            <path
                fill="#000"
                d="M29.322 11.167c-2.573 0-4.658-1.713-4.658-3.723 0-2.008 2.085-3.723 4.658-3.723 2.57 0 5.506 1.715 5.506 3.723 0 2.01-2.936 3.723-5.506 3.723M28.895 0C23.756 0 20 3.423 20 7.444c0 4.015 3.756 7.443 8.895 7.443 5.138 0 10.846-3.428 10.846-7.443C39.741 3.424 34.033 0 28.895 0"
            />
            <path
                fill="#000"
                d="M9.32 11.167c-2.57 0-4.656-1.713-4.656-3.723 0-2.008 2.085-3.723 4.656-3.723 2.57 0 5.507 1.715 5.507 3.723 0 2.01-2.937 3.723-5.507 3.723M8.893 0C3.755 0 0 3.423 0 7.444c0 4.015 3.755 7.443 8.893 7.443 5.138 0 10.844-3.428 10.844-7.443C19.737 3.424 14.031 0 8.893 0"
            />
            <path
                fill="#000"
                d="M9.32 27.167c-2.57 0-4.656-1.714-4.656-3.723S6.749 19.72 9.32 19.72c2.57 0 5.507 1.714 5.507 3.723s-2.937 3.723-5.507 3.723M8.893 16C3.755 16 0 19.423 0 23.444c0 4.017 3.755 7.441 8.893 7.441 5.138 0 10.844-3.424 10.844-7.441 0-4.02-5.706-7.444-10.844-7.444"
            />
        </g>
    </svg>
);
