import React, { FC, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { LinkTo } from './LinkTo';
import { Arrow } from './icons';

export interface TextLinkProps {
    text?: string;
    url?: string;
    hasNoArrow?: boolean;
}

const StyledTextLink = styled(LinkTo)`
    ${({ theme: { colors } }) => css`
        display: inline-flex;
        &:hover, &:focus {
            color: ${colors.accent2.default};
        }
    `}
`;

const StyledArrow = styled(Arrow)`
    ${({ theme: { space } }) => css`
        margin-left: ${space.xs};
    `};
`;

export const TextLink: FC<React.PropsWithChildren<TextLinkProps>> = forwardRef(
    ({ url, text, children, hasNoArrow, ...rest }, ref) => (
        <StyledTextLink to={url} ref={ref} {...rest}>
            {children || text}
            {hasNoArrow ? '' : <StyledArrow />}
        </StyledTextLink>
    )
);

TextLink.displayName = 'TextLink';
