import React, { createContext, useState, useContext, ReactNode } from 'react';

type PageColourContextType = {
  cssOverlay: string;
  setCssOverlay: (value: string) => void;
};

export const PageColourContext = createContext<PageColourContextType | undefined>(undefined);

export const PageColourProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [cssOverlay, setCssOverlay] = useState('');

  return (
    <PageColourContext.Provider value={{ cssOverlay, setCssOverlay }}>
      {children}
    </PageColourContext.Provider>
  );
};

export const usePageColour = () => {
  const context = useContext(PageColourContext);
  if (context === undefined) {
    throw new Error('usePageColour must be used within an PageColourProvider');
  }
  return context;
};