import React, { FC } from 'react';
import { Icon, IconProps } from './Icon';

export const SocialInstagram: FC<IconProps> = (props) => (
    <Icon {...props}>
        <path
            d="M16 6C10.486 6 6 10.486 6 16V32C6 37.514 10.486 42 16 42H32C37.514 42 42 37.514 42 32V16C42 10.486 37.514 6 32 6H16ZM16 10H32C35.308 10 38 12.692 38 16V32C38 35.308 35.308 38 32 38H16C12.692 38 10 35.308 10 32V16C10 12.692 12.692 10 16 10ZM34 12C33.4696 12 32.9609 12.2107 32.5858 12.5858C32.2107 12.9609 32 13.4696 32 14C32 14.5304 32.2107 15.0391 32.5858 15.4142C32.9609 15.7893 33.4696 16 34 16C34.5304 16 35.0391 15.7893 35.4142 15.4142C35.7893 15.0391 36 14.5304 36 14C36 13.4696 35.7893 12.9609 35.4142 12.5858C35.0391 12.2107 34.5304 12 34 12ZM24 14C18.486 14 14 18.486 14 24C14 29.514 18.486 34 24 34C29.514 34 34 29.514 34 24C34 18.486 29.514 14 24 14ZM24 18C27.308 18 30 20.692 30 24C30 27.308 27.308 30 24 30C20.692 30 18 27.308 18 24C18 20.692 20.692 18 24 18Z"
            fill="currentColor"
        />
    </Icon>
);
