import React, { FC } from 'react';
import { Link } from 'gatsby';

interface LinkToProps {
    to: string;
    onClick?: any;
    className?: string;
    state?: any;
    id?: string;
}

export const LinkTo: FC<React.PropsWithChildren<LinkToProps>> = ({
    to,
    children,
    onClick,
    state,
    id,
    ...rest
}) => {
    // if (typeof to !== 'string'){
    //   //console.log('to', to);
    //   return null
    // }

    const domain = process.env.GATSBY_FRONTEND_URL;

    let link = to;
    let isExternal = to?.trim().startsWith('http');
    const isAnchor = to?.trim().startsWith('#');
    const isMail = to?.trim().startsWith('mailto');
    const isTel = to?.trim().startsWith('tel');

    if (
        isExternal &&
        domain &&
        link.includes(domain) &&
        !link.startsWith('https://charitywise')
    ) {
        [, link] = to.split(domain);
        isExternal = false;
    }
    return (
        <>
            {isExternal || isAnchor || isMail || isTel ? (
                <a
                    href={link}
                    target={isExternal ? '_blank' : ''}
                    rel={isExternal ? 'noopener noreferrer' : ''}
                    onClick={onClick}
                    id={id}
                    {...rest}
                >
                    {children}
                </a>
            ) : (
                <Link
                    to={link}
                    id={id}
                    activeClassName="is-active"
                    onClick={onClick}
                    state={state}
                    {...rest}
                >
                    {children}
                </Link>
            )}
        </>
    );
};

export default LinkTo;
