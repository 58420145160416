import { useState, useEffect } from 'react';

const useIsMobile = (breakpoint: number = 991): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const checkIsMobile = () => window.innerWidth <= breakpoint;

    // Set the initial value
    setIsMobile(checkIsMobile());

    // Update the value on window resize
    const handleResize = () => {
      setIsMobile(checkIsMobile());
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [breakpoint]);

  return isMobile;
};

export default useIsMobile;
