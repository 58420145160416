export const colors = {
    // FE Black
    black: {
        default: '#111111',
        contrast: '#FFFFFF',
    },
    // FE White
     white: {
        default: '#FFFFFF',
        contrast: '#111111',
    },
    // FE Blue
    accent1: {
        default: '#0099cc',
        contrast: '#FFFFFF',
    },
    // FE Magenta
    accent2: {
        default: '#CC0066',
        contrast: '#FFFFFF',
    },
    // FE Green
    accent3: {
        default: '#008044',
        contrast: '#FFFFFF',
    },
    // FE Orange
    accent4: {
        default: '#FF6600',
        contrast: '#FFFFFF',
    },
    // FE Grey
    grey1: {
        default: '#BBBBBB',
        contrast: '#111111',
    },
    // FE Grey - Light
    grey2: {
        default: '#EEEEEE',
        contrast: '#111111',
    },

    // Valid - green
    valid: {
        default: '#008044',
        contrast: '#FFFFFF',
    },
    // Invalid - red
    invalid: {
        default: '#CC0066',
        contrast: '#FFFFFF',
    },
    // Transparent
    transparent: {
        default: 'transparent',
        contrast: 'transparent',
    },
};

export default colors;

export type ThemeColors = typeof colors;
export type ThemeColorsKeys = keyof typeof colors;
