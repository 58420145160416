import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { ThemeFontsKeys } from '../styles/tokens/typography';
import { getResponsiveType } from '../utils';

type TypographyComponents =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'p'
    | 'span'
    | 'small'
    | 'strong'
    | 'a';

interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
    component: TypographyComponents;
    variant: ThemeFontsKeys;
}

const StyledTypography = styled.h1<TypographyProps>`
    ${({ variant, theme }) =>
        variant &&
        css`
            ${getResponsiveType(variant, theme, 'md')};
        `};
`;

export const Typography = forwardRef<HTMLElement, TypographyProps>(
    (
        {
            children,
            component: Component = 'p', // Provide a default tag
            variant,
            ...rest
        },
        ref
    ) => (
        <StyledTypography as={Component} variant={variant} ref={ref} {...rest}>
            {children}
        </StyledTypography>
    )
);

Typography.displayName = 'Typography';
