import React, { FC } from 'react';

export const SocialShareLinkedin: FC = (props) => (
    <svg
        width="24"
        height="24"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="#111"
            d="M19.2 3.2H4.8a1.6 1.6 0 0 0-1.6 1.6v14.4a1.6 1.6 0 0 0 1.6 1.6h14.4a1.6 1.6 0 0 0 1.6-1.6V4.8a1.6 1.6 0 0 0-1.6-1.6ZM8.763 17.6h-2.36v-7.594h2.36V17.6ZM7.56 8.92a1.376 1.376 0 1 1 0-2.751 1.376 1.376 0 0 1 0 2.752Zm10.044 8.68h-2.358v-3.693c0-.88-.016-2.013-1.226-2.013-1.228 0-1.417.959-1.417 1.95V17.6h-2.359v-7.594h2.264v1.038h.032c.316-.597 1.085-1.226 2.233-1.226 2.39 0 2.831 1.572 2.831 3.617V17.6Z"
        />
    </svg>
);
