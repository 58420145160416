
const iconSizes = {
    sm: '16 16',
    md: '24 24',
    lg: '32 32',
    xl: '48 48',
    xxl: '64 64',
  };
  
  export default iconSizes;
  export type ThemeIconSizes = typeof iconSizes;
  export type ThemeIconSizesKeys = keyof typeof iconSizes;