import React, { FC } from 'react';
import { Icon, IconProps } from './Icon';

export const SocialLinkedin: FC<IconProps> = (props) => (
    <Icon {...props}>
        <path
            d="M10 6C7.79 6 6 7.79 6 10V38C6 40.21 7.79 42 10 42H38C40.21 42 42 40.21 42 38V10C42 7.79 40.21 6 38 6H10ZM10 10H38V38H10V10ZM16.5586 12.6328C14.8446 12.6328 13.8164 13.6632 13.8164 15.0352C13.8164 16.4072 14.8447 17.4336 16.3867 17.4336C18.1007 17.4336 19.1289 16.4072 19.1289 15.0352C19.1289 13.6632 18.1006 12.6328 16.5586 12.6328ZM13.9531 20V34H19V20H13.9531ZM21.1641 20V34H26.2109V26.3477C26.2109 24.0697 27.8362 23.7422 28.3242 23.7422C28.8122 23.7422 30.1172 24.2317 30.1172 26.3477V34H35V26.3477C35 21.9537 33.0475 20 30.6055 20C28.1635 20 26.8609 20.8131 26.2109 21.9531V20H21.1641Z"
            fill="currentColor"
        />
    </Icon>
);
