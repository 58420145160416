// This file allows for the theme to be extended by the child theme.
import theme from '../theme';
import colorDefaults from './colors';
import borderDefaults from './borders';
import layersDefaults from './layers';
import mediaDefaults from './media';
import maxMediaDefaults from './maxMedia';
import borderRadiiDefaults from './borderRadii';
import shadowDefaults from './shadows';
import spaceDefaults from './space';
import typographyDefaults from './typography';
import animationDefaults from './animation';
import iconSizeDefaults from './iconSizes';
import buttonDefaults from './buttons';
import globalDefaults from './global';

export const colors = { ...colorDefaults, ...(theme.colors || {}) };
export const borders = { ...borderDefaults, ...(theme.borders || {}) };
export const layers = { ...layersDefaults, ...(theme.layers || {}) };
export const media = { ...mediaDefaults, ...(theme.media || {}) };
export const maxMedia = { ...maxMediaDefaults, ...(theme.maxMedia || {}) };
export const space = { ...spaceDefaults, ...(theme.space || {}) };
export const borderRadii = {
    ...borderRadiiDefaults,
    ...(theme.borderRadii || {}),
  };
export const shadows = { ...shadowDefaults, ...(theme.shadows || {}) };
export const typography = {
    ...typographyDefaults,
    ...(theme.typography || {}),
};
export const animation = { ...animationDefaults, ...(theme.animation || {}) };
export const iconSizes = { ...iconSizeDefaults, ...(theme.iconSizes || {}) };
export const buttons = { ...buttonDefaults, ...(theme.buttons || {}) };
export const global = { ...globalDefaults, ...(theme.global || {}) };

export default {
    colors,
    borders,
    global,
    layers,
    media,
    maxMedia,
    space,
    borderRadii,
    shadows,
    animation,
    iconSizes,
    typography,
    buttons,
};
