import React, { FC, useEffect } from 'react';
import { useDataLayerPush } from '../hooks/useDataLayerPush';

export interface DataLayerWrapperProps {
    children: any;
    data: any;
    serverData: any;
    location: any;
}

export const DataLayerWrapper: FC<DataLayerWrapperProps> = ({
    children,
    data,
    serverData,
    location,
}) => {
    const { dataLayerPush } = useDataLayerPush();

    // Handle route change

    useEffect(() => {
        setTimeout(() => {
            dataLayerPush({
                event: 'gatsby-route-change',
            });
        }, 100);
    }, [data]);

    return <>{children}</>;
};
