import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { getResponsiveType } from '../utils/getResponsiveType';
import { createMarkup } from '../utils/createMarkup';
import { Hatch_HtmlContentComponentType } from '../graphqlTypes';

interface HtmlContentProps extends Hatch_HtmlContentComponentType {
    children?: any;
}

const StyledHtmlContent = styled.div`
    ${({ theme }) => css`
        // Common styles

        a {
            &:hover {
                color: ${theme.colors.accent2.default};
            }
        }
        // Tables

        // overwrite FeaturedContent Component using rtl
        direction: initial;

        table {
            border: none;
            border-collapse: collapse;

            td,
            th {
                padding: ${theme.space.sm};
                border-top: 1px solid #979797;
                vertical-align: top;
                ${getResponsiveType('body')}
            }
        }

        // Ul / Ol

        ul,
        ol {
            padding-left: ${theme.space.md};
            ${getResponsiveType('body')}

            li + li {
                margin-top: ${theme.space.xs};
            }
        }

        ul {
            list-style: disc;
        }

        ol {
            list-style: decimal;
        }

        // Spacing between elements

        > * + *:not(.team-member-block) {
            margin-top: ${theme.space.md};
        }

        > * + h2,
        > * + h3 {
            margin-top: ${theme.space.xl};
        }

        > * + h4,
        > * + h5 {
            margin-top: ${theme.space.md};
        }

        // Custom Editor type styles

        .lead,
        .basic-content__lead,
        .section-heading__sub-heading {
            ${getResponsiveType('lead')}
        }
        p[style*='font-size: 90%'] {
            font-size: 15px !important;
        }
        p:not(.lead, .basic-content__lead, .section-heading__sub-heading) {
            ${getResponsiveType('body')}
        }

        h4 {
            + p {
                margin-top: ${theme.space.xs};
                a {
                    + a {
                        display: block;
                        margin-top: ${theme.space.xs};
                    }
                }
            }
        }

        .section-heading__sub-heading {
            margin-bottom: ${theme.space.lg};
        }

        .basic-content__copy {
            > * + * {
                margin-top: ${theme.space.md};
            }
        }

        .button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            position: relative;
            white-space: normal;
            cursor: pointer;
            user-select: none;
            text-decoration: none;
            overflow: visible;
            color: #fff;
            background-color: ${theme.colors.accent2.default};
            padding: ${theme.space.md} ${theme.space.lg};
            max-height: 48px;
            border-radius: ${theme.space.lg};
            border: solid 2px ${theme.colors.accent2.default};
            text-align: center;
            font-weight: 400;
            ${getResponsiveType('body')}
        }

        .card__heading {
            ${getResponsiveType('h3')} !important;
        }
        .card__heading__link {
            text-decoration: none;
            &:hover {
                color: ${theme.colors.accent2.default};
            }
        }

        // Youtube Iframe
        .video-wrapper {
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 30px;
            height: 0;
            overflow: hidden;
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        // Typography

        h1 {
            ${getResponsiveType('h1')}
        }
        h2 {
            ${getResponsiveType('h2')}
        }
        h3 {
            ${getResponsiveType('h3')}
        }
        h4 {
            ${getResponsiveType('h4')}

            + ul {
                margin-top: ${theme.space.xs};
            }
        }
        h5 {
            ${getResponsiveType('h5')}
        }

        blockquote {
            padding: 0 ${theme.space.lg};
            border-left: 10px solid ${theme.colors.grey2.default};
            font-style: italic;
            ${getResponsiveType('body')}

            cite {
                font-style: normal;
                color: ${theme.colors.grey1.default};
                margin-top: ${theme.space.md};
                display: block;
            }

            img {
                float: right;
                width: 150px;
                height: 150px;
                border-radius: 50%;
                overflow: hidden;
            }

            p + p {
                margin-top: ${theme.space.md};
            }
        }

        // Images
        img[style],
        img {
            height: auto;
            max-width: 100%;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    `};
`;

const HtmlContent: FC<HtmlContentProps> = ({ children, ...rest }) => (
    <StyledHtmlContent {...rest}>
        {typeof children === 'string' ? createMarkup(children) : children}
    </StyledHtmlContent>
);

export default HtmlContent;
