import { css } from 'styled-components';
import { math } from 'polished';
import { space, media } from '../styles/tokens';
import { ThemeSpaceKeys } from '../styles/tokens/space';

type UnstyledListProps = number | string;
export const unstyledList = (
    margin: UnstyledListProps = 0,
    padding: UnstyledListProps = 0
) => css`
    list-style: none;
    margin: ${margin};
    padding: ${padding};
`;

// Replaces @include vertical-containers();

export const verticalContainers = (
    vert: ThemeSpaceKeys = 'xl',
    horz: ThemeSpaceKeys = 'md'
) => css`
    padding: ${space[vert]} ${space[horz]};

    @media (min-width: ${media.md}) {
        padding: ${math(`${space[vert]}`)} ${math(`${space[horz]}`)};
    }
`;

// Replaces @include space-items();

export const spaceItems = (size: ThemeSpaceKeys = 'md') => css`
    > * {
        margin-bottom: 0;
    }

    > * + * {
        margin-top: ${space[size]};
    }
`;

export const noScrollbar = () => css`
    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: -ms-autohiding-scrollbar;
`;


export function hasClass(target, className) {
    return new RegExp('(\\s|^)' + className + '(\\s|$)').test(target.className);
  }
  
  export function getCssProperty(elm, property) {
    return window.getComputedStyle(elm, null).getPropertyValue(property);
  }
  