import React, { FC } from 'react';
import { Icon, IconProps } from './Icon';

export const SocialFacebook: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M10 6C7.794 6 6 7.794 6 10V38C6 40.206 7.794 42 10 42H23.2422H28.8281H38C40.206 42 42 40.206 42 38V10C42 7.794 40.206 6 38 6H10ZM10 10H38L38.0039 38H28.8281V30.0703H33.5586L34.2617 24.6211H28.8594V21.1484C28.8594 19.5724 29.2986 18.5078 31.5586 18.5078H34.4141V13.6445C33.0241 13.5025 31.6285 13.4335 30.2305 13.4375C26.0825 13.4375 23.2422 15.9692 23.2422 20.6172V24.6289H18.5547V30.0781H23.2422V38H10V10Z" fill="currentColor"/>
  </Icon>
);



