import React, { FC } from 'react';
import { Mobile } from './icons/Mobile';

interface TelephoneProps {
    displayMode: 'small' | 'large';
    cssClass?: string;
}
export const Telephone: FC<TelephoneProps> = ({ displayMode, cssClass }) => {
    const telnumber = '01903 285900';

    // icttNum
    const clickToCall = (phoneNumber) =>
        `tel:${phoneNumber.replace(/\s/g, '')}`;
    return (
        <>
            {displayMode === 'large' ? (
                <a
                    className={`${cssClass || ''}`}
                    href={clickToCall(telnumber)}
                    data-ict-discovery-number={telnumber}
                    data-ict-silent-replacements="true"
                >
                    <span>{telnumber}</span>
                </a>
            ) : (
                <a className="link" href={clickToCall(telnumber)}>
                    <span className="label">Call us</span>
                    <span className="number">{telnumber}</span>
                    <span className="icon">
                        <Mobile />
                    </span>
                </a>
            )}
        </>
    );
};
