import React, { FC, forwardRef, RefObject } from 'react';
import styled, { css } from 'styled-components';
import { maxWidths, snapPoints, verticalContainers } from '../utils';

interface ContainerProps {
    skinny?: boolean;
    borderBottom?: boolean;
    band?: boolean;
    children: any;
}

interface StyledContainerProps {
    skinny?: boolean;
    borderBottom?: boolean;
    band?: boolean;
}

const StyledContainer = styled.div<StyledContainerProps>`
    ${({ theme: { space, media, colors }, skinny, borderBottom, band }) => css`
        margin-left: auto;
        margin-right: auto;

        ${band
            ? verticalContainers('none')
            : css`
                  padding-left: ${space.md};
                  padding-right: ${space.md};
                  @media (min-width: ${media.lg}) {
                      padding-left: ${space.xl};
                      padding-right: ${space.xl};
                  }
              `};

        ${maxWidths(snapPoints, media)}

        ${skinny &&
        css`
            max-width: 864px !important;
        `}

    ${borderBottom &&
        css`
            padding-bottom: ${space.md};
            position: relative;

            &:after {
                content: '';
                display: block;
                margin: 0 auto;
                width: calc(100% - ${space.xl});
                border-bottom: 1px solid ${colors.grey1.default};
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                @media (min-width: ${media.lg}) {
                    width: calc(100% - ${space.xl});
                }
            }

            @media (min-width: ${media.md}) {
                padding-bottom: ${space.xl};
            }
        `}
    `}
`;

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
    ({ children, skinny, borderBottom, ...rest }, ref) => (
        <StyledContainer ref={ref} skinny={skinny} borderBottom={borderBottom} {...rest}>
            {children}
        </StyledContainer>
    )
);

export default Container;