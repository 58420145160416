import React, { FC, useState } from 'react';
import MicroModal from 'react-micro-modal';
import styled, { css } from 'styled-components';
import { isBrowser } from '../utils';
import { NewsletterSignupForm } from './NewsletterSignupForm';
import { Button } from './Button';
import { Typography } from './Typography';
import { Spacer } from './Spacer';
import { useHasMounted } from '../hooks/useHasMounted';

const StyledNewsletterSignupCta = styled.div`
    ${({ theme: { colors, media } }) => css`
        background-color: ${colors.black.default};
        color: ${colors.white.default};
        @media (min-width: ${media.lg}) {
            text-align: right;
        }
    `}
`;
const StyledNewsletterSignupCtaHeading = styled(Typography)`
    ${({ theme: { colors } }) => css``}
`;

const NewsletterSignupCta = ({ pathname }) => {
    const hasMounted = useHasMounted();
    return (
        <StyledNewsletterSignupCta>
            <Spacer spacing="half">
                <StyledNewsletterSignupCtaHeading component="h4" variant="h4">
                    Sign up for regular industry <br /> news updates
                </StyledNewsletterSignupCtaHeading>

                <div>
                    {/* {isBrowser && hasMounted && (
                        <MicroModal
                            trigger={(open) => (
                                <Button
                                    type="button"
                                    onClick={open}
                                    isButton
                                    hasNoArrow
                                    variant="outlineReverse"
                                >
                                    Newsletter sign up
                                </Button>
                            )}
                            overrides={{
                                Dialog: {
                                    className: 'modal modal--standout',
                                    style: {
                                        padding: '0',
                                        backgroundColor: 'transparent',
                                        maxWidth: 'none',
                                    },
                                },
                            }}
                        >
                            {(close) => (
                                <NewsletterSignupForm
                                    close={close}
                                    pathname={pathname}
                                />
                            )}
                        </MicroModal>
                    )} */}
                    <Button
                        type="button"
                        url='/email-sign-up'
                        hasNoArrow
                        variant="outlineReverse"
                        text='Newsletter sign up'
                    />
                        
                </div>
            </Spacer>
        </StyledNewsletterSignupCta>
    );
};

export default NewsletterSignupCta;
