/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */

import { Hatch_ContentMenuItemType } from '../graphqlTypes';

interface FlatListPropsConfig {
    parentKey: string;
    childrenKey: string;
    idKey: string;
}

type FlatListData = [Hatch_ContentMenuItemType] | [];

export const flatListToHierarchical = (
    data: FlatListData = [],
    { idKey = 'id', parentKey = 'parentMenuItemId', childrenKey = 'childMenuItems' }: any = {}
) => {
    const tree = [];
    const childrenOf = {};
    data.forEach((item) => {
        const newItem = { ...item };
        const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
        childrenOf[id] = childrenOf[id] || [];
        newItem[childrenKey] = childrenOf[id];

        parentId ? (childrenOf[`${parentId}`] = childrenOf[`${parentId}`] || []).push(newItem) : tree.push(newItem);
    });

    return tree;
};
