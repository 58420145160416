import React, { FC, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { getButtonStyles } from '../utils/getButtonStyles';
import { ThemeButtonKeys } from '../styles/tokens/buttons';
import { LinkTo } from './LinkTo';
import { Arrow, ArrowLeft } from './icons';

export interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'disabled'> {
    variant?: ThemeButtonKeys;
    text?: string;
    anchor?: string;
    url?: string;
    a11yTitle?: string;
    target?: boolean;
    selected?: boolean;
    disabled?: boolean;
    hasNoArrow?: boolean;
    arrowLeft?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
}

type StyledButtonProps = Pick<ButtonProps, 'variant' | 'disabled' | 'selected'>;

export const StyledButton = styled.a<StyledButtonProps>`
    ${({ theme: { space }, variant, selected, disabled }) => css`
        display: inline-flex;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        padding: 14px ${space.md};
        text-decoration: none;

        /* Color/ type / border / radius styles */
        ${variant && getButtonStyles(variant, selected, disabled)};
    `};
`;

const StyledArrow = styled(Arrow)`
    ${({ theme: { space } }) => css`
        margin-left: ${space.xs};
        width: 16px;
        height: 16px;
    `};
`;

const StyledArrowLeft = styled(ArrowLeft)`
    ${({ theme: { space } }) => css`
        margin-right: ${space.xs};
        width: 16px;
        height: 16px;
    `};
`;

export const Button: FC<React.PropsWithChildren<ButtonProps>> = forwardRef(
    (
        {
            children,
            a11yTitle,
            onClick,
            disabled = false,
            variant = 'solid',
            anchor,
            url,
            target,
            text,
            hasNoArrow,
            arrowLeft,
            selected = false,
            ...rest
        },
        ref
    ) => {
        if ((!text && !children) || text === 'null') {
            return null;
        }

        const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
            if (onClick) {
                onClick(event);
            }
        };

        const isLink = !!url || !!anchor;
        const href = url || (anchor && `#${anchor}`);

        return isLink ? (
            <StyledButton
                ref={ref}
                as={LinkTo}
                to={href}
                onClick={handleClick}
                aria-label={a11yTitle}
                variant={variant}
                disabled={disabled}
                selected={selected}
                aria-disabled={disabled}
                target={target && '__blank'}
                rel={target ? 'noopener noreferrer' : undefined}
                {...rest}
            >
                {arrowLeft ? <StyledArrowLeft /> : ''}

                {children || text}

                {hasNoArrow || arrowLeft ? '' : <StyledArrow />}
            </StyledButton>
        ) : (
            <StyledButton
                ref={ref}
                as="button"
                onClick={handleClick}
                aria-label={a11yTitle}
                variant={variant}
                disabled={disabled}
                selected={selected}
                aria-disabled={disabled}
                {...rest}
            >
                {arrowLeft ? <StyledArrowLeft /> : ''}

                {children || text}

                {hasNoArrow || arrowLeft ? '' : <StyledArrow />}
            </StyledButton>
        );
    }
);

Button.displayName = 'Button';
