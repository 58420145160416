import React, { FC } from 'react';

export const SocialShareTwitter: FC = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        {...props}
    >
        <path
            fill="#111"
            fillRule="evenodd"
            d="M4.8 3.2h14.4a1.6 1.6 0 0 1 1.6 1.6v14.4a1.6 1.6 0 0 1-1.6 1.6H4.8a1.6 1.6 0 0 1-1.6-1.6V4.8a1.6 1.6 0 0 1 1.6-1.6ZM16.674 7l-3.723 4.446L17 17.5h-2.978l-2.726-4.077L7.882 17.5H7l3.904-4.662L7 7h2.978l2.582 3.86L15.791 7h.882Zm-5.327 5.308.396.581 2.694 3.96h1.355l-3.301-4.853-.396-.58-2.54-3.734H8.2l3.147 4.626Z"
            clipRule="evenodd"
        />
    </svg>
);
