/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { FC, useMemo, useRef, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import Headroom from 'react-headroom';
import { Hatch_ContentMenuItemType } from '../graphqlTypes';
import { flatListToHierarchical } from '../utils/flatListToHierarchical';
import { getResponsiveType } from '../utils/getResponsiveType';
import { Telephone } from './Telephone';
import {
    noScrollbar,
    isBrowser,
} from '../utils';
import { FeLogoBlack } from './icons/FeLogoBlack';
import { FeLogoEggsBlack } from './icons/FeLogoEggsBlack';
import { IconButton } from './IconButton';
import { Nav } from './Nav';
interface HeaderProps {
    siteTitle?: string;
    menu: [Hatch_ContentMenuItemType];
}

interface HierarchicalMenuProps extends Hatch_ContentMenuItemType {
    childMenuItems: [HierarchicalMenuProps];
}

interface StyledHeaderProps {
    isPinned?: boolean;
}

const StyledBurgerButton = styled.button`
  ${({ theme: { maxMedia, space } }) => css`
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: ${space.sm};

    @media (max-width: ${maxMedia.lg}) {
      display: block;
    }

    span {
      display: block;
      width: 25px;
      height: 3px;
      background-color: #000;
      margin: 5px 0;
      transition: all 0.3s ease;
    }

    &.active {
      span:nth-child(1) {
        transform: rotate(-45deg) translate(-5px, 6px);
      }
      span:nth-child(2) {
        opacity: 0;
      }
      span:nth-child(3) {
        transform: rotate(45deg) translate(-5px, -6px);
      }
    }
  `}
`;

const StyledPrimaryNavOverlay = styled.div`
  ${({ theme: { layers, media } }) => css`
    @media (min-width: ${media.lg}) {
      background: rgba(17, 17, 17, 0.75);
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      pointer-events: none;
      z-index: ${layers.bgOverlay};
    }
  `};
`;

const StyledHeader = styled.header<StyledHeaderProps>`
    ${({ theme: { media, colors, layers, space }, isPinned }) => css`
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        /* transition: all 0.2s ease-in; */
        background: ${colors.white.default};
        z-index: ${layers.header};
        border-bottom: 1px solid ${colors.white.default};

        @media (min-width: ${media.lg}) {
            height: 104px;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;
            padding: ${space.md} 0;
            background: ${colors.white.default};
        }
        ${isPinned &&
        css`
            position: fixed;
            height: 52px;

            @media (min-width: ${media.lg}) {
                height: 72px;
                border-bottom: 1px solid ${colors.grey2.default};
            }
        `}
    `}
`;

const StyledHeaderContainer = styled.div<StyledHeaderProps>`
    ${({ theme: { media, colors, space }, isPinned }) => css`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 0;
        
        /* transition: all 0.2s ease-in; */
        ${isPinned &&
        css`
            position: fixed;
            height: 52px;

            @media (min-width: ${media.lg}) {
                height: 72px;
                border-bottom: 1px solid ${colors.grey2.default};
                
            }
        `}

        @media (min-width: ${media.lg}) and (max-width: 1180px) {
            padding: 0 ${space.md};
        }

        @media (min-width: ${media.lg}) {
                max-width: 1140px;
                margin: 0 auto;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                bottom  0;
            }
    `}
`;

const StyledHeaderInner = styled.div<StyledHeaderProps>`
    ${({ theme: { media, layers, space }, isPinned }) => css`
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        width: 100%;
        margin: 0 auto;
        padding: 0 ${space.md};
        /* transition: all 0.5s ease-in; */
        z-index: ${layers.header};
        box-shadow: 0px 18px 64px -64px rgba(17, 17, 17, 0.15), 0px 4px 24px -3px rgba(17, 17, 17, 0.16);

        ${isPinned &&
        css`
            height: 52px;
        `}

        @media (min-width: ${media.lg}) {
            height: 52px;
        }
        
        @media (min-width: ${media.lg}) {
            width: auto;
            height: auto;
            box-shadow: none;
            margin: 0;
            padding: 0;

            ${isPinned &&
            css`
                display: flex;
            `}
        }
    `};
`;

const StyledLogo = styled(Link)<StyledHeaderProps>`
    ${({ theme: { media }, isPinned }) => css`
        padding: 0;
        margin: 24px 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        height: 31px;
        width: 42px;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        @media (min-width: ${media.lg}) {
            margin: 0;
        }

        @media (min-width: ${media.lg}) {
            height: 88px;
            width: 83px;

            ${isPinned &&
            css`
                height: 40px;
            `}
        }
    `};
`;

const StyledLogoSm = styled.span<StyledHeaderProps>`
    ${({ theme: { media }, isPinned }) => css`
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        display: none;
        transition: opacity 0.2s ease-in;
        height: 31px;
        @media (min-width: ${media.lg}) {
            transform: translate(-50%, -400%);
            display: block;
            opacity: 0;
            ${isPinned &&
            css`
                transform: translateY(-50%);
                opacity: 1;
            `}
        }
    `}
`;
const StyledLogoLg = styled.span<StyledHeaderProps>`
    ${({ theme: { media }, isPinned }) => css`
        position: absolute;
        top: 10%;
        left: 0;
        display: block;
        transition: opacity 0.2s ease-in;
        transform: translateY(-50%);
        height: 35px;

        @media (min-width: ${media.lg}) {
            transform: translateY(-50%);
            display: block;
            opacity: 1;
            height: 60px;
            top: 50%;

            ${isPinned &&
            css`
                transform: translate(-50%, -400%);
                opacity: 0;
            `}
        }
    `}
`;


const StyledCallSm = styled.div`
    ${({ theme: { media, maxMedia, space, colors } }) => css`
        display: none;
        color: ${colors.black.default};

        @media (min-width: ${media.lg} ) {
            display: block;
        }
        
        @media (min-width: ${media.lg}) {
            display: none;
        }

        .link {
            color: inherit;
            display: flex;
            flex-flow: row nowrap;
            .is-active-dropdown & {
                @media (max-width: ${maxMedia.md}) {
                    display: none;
                }
            }
        }
        .icon {
            display: block;
            width: 14px;
            height: 26px;
            border-radius: 4px;
            margin-left: ${space.sm};
        }

        .number {
            display: none;
            @media (min-width: ${media.md}) {
                display: inline;
            }
        }
        .label {
            text-decoration: none;
            ${getResponsiveType('body')}
            @media (min-width: ${media.md}) {
                display: none;
            }
        }
    `};
`;
const StyledCallLg = styled.div`
    ${({ theme: { media, colors } }) => css`
        display: none;
        color: ${colors.black.default};
        /* height: 40px; */ 

        @media (min-width: ${media.lg}) {
            display: block;
        }

        a {
            text-decoration: none;
            color: ${colors.black.default};
            ${getResponsiveType('h3')}
        }
    `};
`;

const StyledTelephoneSm = styled.div`
    ${({ theme: { media, colors } }) => css`
        &:hover .icon {
            animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: 1000px;
        }

        @keyframes shake {
            10%,
            90% {
                transform: translate3d(-1px, 0, 0);
            }

            20%,
            80% {
                transform: translate3d(2px, 0, 0);
            }

            30%,
            50%,
            70% {
                transform: translate3d(-4px, 0, 0);
            }

            40%,
            60% {
                transform: translate3d(4px, 0, 0);
            }
        }
    `};
`;

const StyledHeadNav = styled.div<{ isOpen: boolean }>`
  ${({ theme: { media, maxMedia, space, colors }, isOpen }) => css`
    white-space: nowrap;
    /* overflow: hidden; */
    text-align: center;
    margin: 0 auto;
    width: 100%;
    

    @media (max-width: ${maxMedia.lg}) {
      display: ${isOpen ? 'block' : 'none'};
      position: absolute;
      height: 100vh;
      top: 100%;
      left: 0;
      right: 0;
      background-color: ${colors.white.default};
      z-index: ${({ theme }) => theme.layers.header - 1};
    }

    @media (min-width: ${media.lg}) {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      margin: 0 ${space.md};
      border: none;
      width: auto;
    }
  `}
`;

export const Header: FC<HeaderProps> = ({ siteTitle, menu }) => {
    const [isPinned, setIsPinned] = useState(false);
    const [isDropdownActive, setIsDropdownActive] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const clickRef = useRef<HTMLDivElement>(null);
    const timeOutRef = useRef<any>();
  
    const menuItems: any[] = useMemo(
      () => (menu && menu.length >= 1 ? flatListToHierarchical(menu) : []),
      [menu]
    );
  
    const handleDropdownActiveChange = (isActive: boolean) => {
      setIsDropdownActive(isActive);
    };
  
    const toggleMobileMenu = () => {
      setIsMobileMenuOpen(!isMobileMenuOpen);
    };
  
    const handleOnTop = () => {
      if (typeof document !== 'undefined') {
        document.body.classList.remove('header-pinned');
        setIsPinned(false);
      }
    };
  
    const handleOnNotTop = () => {
      if (typeof document !== 'undefined') {
        document.body.classList.add('header-pinned');
        setIsPinned(true);
      }
    };
  
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 20 && !isPinned) {
          handleOnNotTop();
        } else if (window.scrollY <= 20 && isPinned) {
          handleOnTop();
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [isPinned]);
  
    return (
      <Headroom disableInlineStyles>
        <StyledHeader isPinned={isPinned} ref={clickRef}>
          <StyledHeaderContainer isPinned={isPinned}>
            <StyledHeaderInner isPinned={isPinned}>
              <StyledLogo to="/" className="header__logo" title={siteTitle}>
                <StyledLogoSm isPinned={isPinned}>
                  <FeLogoEggsBlack />
                </StyledLogoSm>
                <StyledLogoLg isPinned={isPinned}>
                  <FeLogoBlack />
                </StyledLogoLg>
              </StyledLogo>
  
              <StyledCallSm>
                <StyledTelephoneSm>
                  <Telephone displayMode="small" className="telephone" />
                </StyledTelephoneSm>
              </StyledCallSm>
  
              <StyledBurgerButton
                onClick={toggleMobileMenu}
                className={isMobileMenuOpen ? 'active' : ''}
                aria-expanded={isMobileMenuOpen}
                aria-label="Toggle mobile menu"
              >
                <span></span>
                <span></span>
                <span></span>
              </StyledBurgerButton>
            </StyledHeaderInner>
  
            <StyledHeadNav isOpen={isMobileMenuOpen}>
              <Nav items={menuItems} onDropdownActiveChange={handleDropdownActiveChange} debugForceHoverFirstItem={false} toggleMobileMenu={toggleMobileMenu} />
            </StyledHeadNav>
  
            <StyledCallLg>
              <Telephone displayMode="large" cssClass="" />
            </StyledCallLg>
          </StyledHeaderContainer>
        </StyledHeader>
        {isDropdownActive && <StyledPrimaryNavOverlay />}
      </Headroom>
    );
  };
  
  export default Header;
