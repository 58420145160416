import React from 'react';
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import { replaceSiteUrl } from './replaceSiteUrl';
import { LinkTo } from '../components/LinkTo';

const options: HTMLReactParserOptions = {
    replace: (domNode) => {
        // Use Gatsby Link
        if (domNode.attribs && domNode.name === 'a') {
            const { href } = domNode.attribs;
            return (
                <LinkTo to={href}>
                    {domNode.children && domToReact(domNode.children)}
                </LinkTo>
            )
        }
        // remove wistia as its global
        if (
            domNode.type === 'script' &&
            domNode?.attribs?.src === 'https://fast.wistia.com/assets/external/E-v1.js'
        ) {
            return null;
        }
        if (domNode.name === 'img' && domNode.attribs && domNode.attribs.style) {
            delete domNode.attribs.style;
            return domToReact(domNode);
        }

        if (domNode.type === 'script' && typeof document !== `undefined`) {
            const externalScript = !!domNode.attribs.src;

            const script = document.createElement('script');
            if (externalScript) {
                script.src = domNode.attribs.src;
                document.head.append(script);
            } else {
                // Do nothing
            }
        }
    },
};

export const createMarkup = (html) => {
    let checkHtml = html;
    if (!checkHtml) {
        return null;
    }
    if (Array.isArray(checkHtml)) {
        checkHtml = checkHtml.join('');
    }
    if (typeof checkHtml !== 'string') {
        return null;
    }

    const videoWrapperDiv = {
        start: '<div class="video-wrapper">',
        end: '</div>',
    };
    const iframeRegex = /<iframe.*?<\/iframe>/g;

    const output: string = checkHtml.replace(
        iframeRegex,
        (match) => videoWrapperDiv.start + match + videoWrapperDiv.end
    );

    return parse(replaceSiteUrl(output), options);
};