import { css } from 'styled-components';
import { ThemeButtonKeys } from '../styles/tokens/buttons';
import { fontWeight } from '../styles/tokens/typography';
import { buttons, colors, borders, borderRadii } from '../styles/tokens';
import { getResponsiveType } from './getResponsiveType';

export const getButtonStyles = (
    variant: ThemeButtonKeys,
    selected = false,
    disabled = false
) => {
    if (!variant) {
        return;
    }

    if (!buttons[variant]) {
        console.warn(`*** ${variant} NOT FOUND! ***`);
        return;
    }

    const buttonVariant = buttons[variant];
    // console.log(buttonVariant);

    return css`
        background-color: ${colors[buttonVariant.background].default};
        border: ${borders[buttonVariant.border]};
        border-radius: ${borderRadii[buttonVariant.borderRadius]};
        ${getResponsiveType(buttonVariant.type)}
        font-weight: ${fontWeight.bold};
        color: ${colors[buttons[variant].color].default};

        &:hover {
            background-color: ${colors[buttonVariant.hover.background].default};
            border: ${borders[buttonVariant.hover.border]};
            ${buttonVariant.hover?.color &&
            `color: ${colors[buttonVariant.hover.color].default}`};
            text-decoration: ${buttonVariant.hover.underline
                ? `underline `
                : `none`};
        }

        ${selected &&
        Object.prototype.hasOwnProperty.call(buttonVariant, 'selected') &&
        css`
            ${buttonVariant.selected?.background &&
            `background-color: ${
                colors[buttonVariant.selected.background].default
            }`};
            ${buttonVariant.selected?.border &&
            `border: ${borders[buttonVariant.selected.border]}`};
            ${buttonVariant.selected?.color &&
            `color: ${colors[buttonVariant.selected.color].default}`};

            &:hover {
                cursor: default;
                ${buttonVariant.selected?.background &&
                `background-color: ${
                    colors[buttonVariant.selected.background].default
                }`};
                ${buttonVariant.selected?.border &&
                `border: ${borders[buttonVariant.selected.border]}`};
                ${buttonVariant.selected?.color &&
                `color: ${colors[buttonVariant.selected.color].default}`};
            }
        `}

        ${disabled &&
        Object.prototype.hasOwnProperty.call(buttonVariant, 'disabled') &&
        css`
            cursor: default;
            background-color: ${colors[buttonVariant.disabled.background]
                .default};
            border: ${borders[buttonVariant.disabled.border]};
            ${buttonVariant.disabled?.color &&
            `color: ${colors[buttonVariant.disabled.color].default}`};

            &:hover {
                background-color: ${colors[buttonVariant.disabled.background]
                    .default};
                border: ${borders[buttonVariant.disabled.border]};
                ${buttonVariant.disabled?.color &&
                `color: ${colors[buttonVariant.disabled.color].default}`};
            }
        `}
    `;
};
