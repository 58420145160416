import { space } from './space';

const borderRadii = {
    base: space.xs,
    lg: space.md,
    buttons: space.xs,
};

export default borderRadii;

export type ThemeBorderRadii = typeof borderRadii;
export type ThemeBorderRadiiKeys = keyof typeof borderRadii;
