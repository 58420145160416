import { css } from 'styled-components';
import { ThemeColorsKeys } from '../styles/tokens/colors';
import { colors } from '../styles/tokens';

export const getBackgroundColor = (color: ThemeColorsKeys) => {
    if (!color) {
        return;
    }

    return css`
        background-color: ${colors[color].default};
        color: ${colors[color].contrast};
    `;
};
