import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Container } from './Container';
import { getResponsiveType } from '../utils/getResponsiveType';
import { Arrow, ArrowLeft } from './icons';
import { Typography } from './Typography';
import { TextLink } from './TextLink';
import HtmlContent from './HtmlContent';
import useIsMobile from '../hooks/useIsMobile';

const animateInToFlex = keyframes`
  0% {
    display: none;
    opacity: 0;
  }
  100% {
    display: flex;
    opacity: 1;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(130%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(130%);
  }
`;

export type Columns = '1' | '2' | '3';

export interface NavProps {
  items?: any;
  onDropdownActiveChange?: (isActive: boolean) => void;
  debugForceHoverFirstItem?: boolean; 
  toggleMobileMenu: () => void;
}

interface NavItemProps {
  item: {
    id: string;
    title: string;
    description?: string;
    url?: string;
    childMenuItems?: any[];
    introContent?: any;
  };
  label?: string;
  setDropdownActive?: any;
  numberColumns?: Columns;
}

interface IntroContentProps {
  item?: any;
}

interface UpSellBlockProps {
  item?: any;
}

interface SubNavItemsProps {
  item?: any;
  setSubItemUpsellBlockActive: (active: boolean) => void;
  shouldFocus?: boolean;
  parentUrl?: string;
}

interface StyledPrimaryNavLinkProps {
  $dropdownIsActive?: boolean;
}

interface StyledSubNavProps {
  $dropdownIsActive?: boolean;
  $dropdownWidth?: Columns;
}

interface StyledUpSellBlockProps {
  $bgrColor: string;
  $txtColor: string;
}

interface StyledDropDownBoxProps {
  width: number;
  height: number;
  top: number;
  left: number;
}

interface StyledDropDownArrowProps {
  widthPos: number;
  leftPos: number;
}

interface StyledNavProps {
  arrowLeftPos: number;
  dropdownActive: boolean;
}

const styleSettings = {
  oneColumn: '456px',
  twoColumns: '912px',
  threeColumns: '1224px',
  upSellBoxTwoColumns: '',
  upSellBoxThreeColumns: '',
};

const debugLog = (...args) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(...args);
    }
  };

  const StyledNav = styled.nav<StyledNavProps>`
  ${({ theme: { colors, space, layers, media }, arrowLeftPos, dropdownActive }) => css`
    background-color: ${colors.white.default};
    padding: ${space.lg};
    position: relative;
    z-index: ${layers.header};
    overflow: visible;

    @media (min-width: ${media.lg}) {
      padding: ${space.sm} 0;
    }
    &:before {
      content: '';
      display: ${dropdownActive ? 'block' : 'none'};
      position: absolute;
      top: 98px;
      left: ${arrowLeftPos}px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent #fff transparent;
      transition: 0.25s ease;
    }
  `};
`;

const StyledNavContainer = styled(Container)`
  ${({ theme: { media } }) => css`
    position: relative;
    padding: 0;
    
    @media (min-width: ${media.lg}) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
    }
  `}
`;

const StyledNavItems = styled.ul`
  ${({ theme: { media } }) => css`
    list-style: none;
    width: 100%;

    @media (min-width: ${media.lg}) {
      display: flex;
      width: auto;

      > li > a > svg {
        display: none;
      }
      ul {
        flex-direction: column;
        position: relative;
      }
    }
  `}
`;

const StyledNavItemLi = styled.li<StyledSubNavProps>`
  ${({ theme: { media }, $dropdownWidth }) => {
    let position = '';

    if ($dropdownWidth === '1') {
      position = 'relative';
    }
    if ($dropdownWidth === '2') {
      position = 'inherit';
    }
    if ($dropdownWidth === '3') {
      position = 'inherit';
    }

    return css`
      margin: 0;
      @media (min-width: ${media.lg}) {
        position: ${position};
      }
    `;
  }};
`;

const StyledNavItemArrow = styled(Arrow)`
  ${({ theme: {media} }) => css`
    display: block;

    @media (min-width: ${media.lg}) {
        display: none;
      }
  `}
`;

const StyledNavItemButton = styled.button<{ $dropdownIsActive: boolean }>`
  ${({ theme: { colors, space, media }, $dropdownIsActive }) => css`
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: ${space.sm} 0;
    ${getResponsiveType('bodyBold')}
    color: inherit;
    text-align: left;

    &:hover, &:focus {
      color: ${colors.accent2.default};
    }

    @media (min-width: ${media.lg}) {
      padding: ${space.xs} ${space.sm};
    }

    ${$dropdownIsActive &&
    css`
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left:-100px;
        width: 400%;
        height: ${space.xl};
      }
    `};
  `}
`;

const StyledNavItemLabel = styled(Link)<StyledPrimaryNavLinkProps>`
  ${({ theme: { colors, space, media }, $dropdownIsActive }) => css`
    width: 100%;
    padding: ${space.sm} 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    ${getResponsiveType('bodyBold')}

    &:hover, &:focus {
      cursor: pointer;
      color: ${colors.accent2.default};
    }

    @media (min-width: ${media.lg}) {
      position: relative;
      padding: ${space.xs} ${space.sm};
      ${$dropdownIsActive &&
      css`
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 100%;
          left:-100px;
          width: 400%;
          height: ${space.xl};
        }
      `};
    }
  `}
`;

const StyledSubSubNavItemsUL = styled.ul<StyledPrimaryNavLinkProps>`
  ${({ theme: { media, space }, $dropdownIsActive }) => css`
    display: ${$dropdownIsActive ? 'block' : 'none'};
    list-style: none;
    width: 100%;

    @media (min-width: ${media.lg}) {
      display: flex;
      gap: ${space.sm};
      width: auto;
      padding-top: ${space.sm};
      flex-direction: column;
      li a {
        ${getResponsiveType('body')}
      }
    }
  `}
`;

const StyledNavItemSubLi = styled.li`
  ${({ theme: {} }) => css`
    margin: 0;
  `};
`;

const StyledNavItemSubLabel = styled(Link)`
  ${({ theme: { media, space, borderRadii, colors } }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    ${getResponsiveType('bodyBold')}
    padding: ${space.sm} 0;

    &:hover, &:focus {
      cursor: pointer;
      text-decoration: underline;
      color: ${colors.accent2.default};
    }

    @media (min-width: ${media.lg}) {
      padding: ${space.sm} ${space.md};
      &:hover, &:focus {
        background: #faeef4;
        border-radius: ${borderRadii.base};
        text-decoration: none;
      }
    }
  `}
`;

const StyledIntroContent = styled.div`
  ${({ theme: { space, colors, media } }) => css`
    margin-top: ${space.md};
    border-top: 1px solid ${colors.grey2.default};
    padding-top: ${space.md};
    @media (min-width: ${media.lg}) {
      padding: 40px ${space.md} ${space.sm};
      margin-top: ${space.md};
    }
  `};
`;

const StyledIntroContentHeading = styled(Typography)`
  ${({ theme: { space } }) => css`
    margin-bottom: ${space.xs};
    text-align: left;
  `};
`;

const StyledIntroContentBody = styled(Typography)`
  ${({ theme: {} }) => css`
    white-space: normal;
    text-align: left;
  `};
`;

const StyledBackButton = styled.button`
  ${({ theme: { space, media, colors } }) => css`
    margin-bottom: ${space.md};
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colors.grey2.default};
    padding-bottom: ${space.md};
    width: 100%;
    svg {
      margin-right: ${space.xs};
    }
    @media (min-width: ${media.lg}) {
      display: none;
    }
  `};
`;

const StyledDropdown = styled.ul<StyledPrimaryNavLinkProps>`
  ${({ theme: { media }, $dropdownIsActive }) => css`
    display: ${$dropdownIsActive ? 'block' : 'none'};

    @media (min-width: ${media.lg}) {
      max-width: 360px;
      position: relative;
      height: 100%;
      display: flex;
    }
  `}
`;

const StyledUpSellBlock = styled.div<StyledUpSellBlockProps>`
  ${({
    theme: { media, space, borderRadii, layers },
    $bgrColor,
    $txtColor,
  }) => css`
    display: none;
    @media (min-width: ${media.lg}) {
      background-color: #fff;
      top: 0;
      right: 0;
      width: 360px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: ${$bgrColor};
      margin: 0;
      padding: ${space.xl};
      color: ${$txtColor};
      border-radius: ${borderRadii.base};
      z-index: ${layers.dropDown};
      ${StyledUpSellBlockSubHeading} {
        opacity: 0.5;
      }
      ${StyledUpSellBlockLinkText} {
        &:hover, &:focus {
          color: ${$txtColor};
        }
      }
    }
  `};
`;

const StyledDropdownWrapper = styled.div<StyledSubNavProps>`
  ${({
    theme: { maxMedia, space, media, layers },
    $dropdownWidth,
    $dropdownIsActive,
  }) => {
    let containerWidth = styleSettings.oneColumn;

    if ($dropdownWidth === '1') {
      containerWidth = styleSettings.oneColumn;
    }
    if ($dropdownWidth === '2') {
      containerWidth = styleSettings.twoColumns;
    }
    if ($dropdownWidth === '3') {
      containerWidth = styleSettings.threeColumns;
    }

    return css`
      @media (max-width: ${maxMedia.lg}) {
        position: absolute;
        top: 0;
        left: -${space.lg};
        right: ${space.sm};
        width: calc(100% + ${space.lg} + ${space.sm});
        min-height: 100%;
        height: 100vh;
        background: #fff;
        display: flex;
        animation: ${$dropdownIsActive
          ? css`
                ${slideIn} 0.3s forwards
            `
          : css`
                ${slideOut} 0.01s forwards
            `};
      }

      @media (min-width: ${media.lg}) {
        display: ${$dropdownIsActive ? 'flex' : 'none'};
        animation: none;
        max-width: 862px;
        --containerWidth: ${containerWidth};
        z-index: ${layers.dropDown};
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% + ${space.xl});
        padding: ${space.xl};
        width: var(--containerWidth);
        display: none;
        opacity: 0;
        
        ${$dropdownIsActive &&
        css`
          animation: ${animateInToFlex} 0.25s linear forwards;
          animation-delay: 0.1s;
          display: flex;
        `}
        
      }
      @media (min-width: 1224px) {
        max-width: inherit;
      }
    `;
  }};
`;

const StyledDropdownSubWrapper = styled.div<StyledSubNavProps>`
  ${({ theme: { maxMedia, space, media, layers }, $dropdownIsActive }) => css`
    z-index: ${layers.dropDown};
    display: ${$dropdownIsActive ? 'block' : 'block'};
    animation: ${$dropdownIsActive
      ? css`
            ${slideIn} 0.3s forwards
        `
      : css`
            ${slideOut} 0.3s forwards
        `};

    ul li {
      a {
        font-weight: normal;
      }
    }
    @media (max-width: ${maxMedia.lg}) {
      position: absolute;
      top: 0;
      width: 100%;
      min-height: 100%;
      height: 100vh;
      background: #fff;
    }
    @media (min-width: ${media.lg}) {
      display: ${$dropdownIsActive ? 'block' : 'none'};
      animation: none;
      background-color: #fff;
      position: absolute;
      left: 100%;
      padding-left: ${space.xl};
      top: 0;
      width: 360px;
      height: 100%;
      max-width: 862px;
      overflow: hidden;
      ul li {
        a {
          padding: 0;
        }
      }
    }
    @media (min-width: 1224px) {
      max-width: inherit;
      overflow: visible;
    }
  `}
`;

const StyledDropdownWrapperInner = styled.div`
  ${({ theme: { media, space } }) => css`
    width: calc(100% - 24px);
    padding: 0 ${space.lg};

    @media (min-width: ${media.lg}) {
      position: relative;
      padding: 0;
      width: 1120px;
      display: flex;
      justify-content: space-between;
    }
  `}
`;

const StyledDropdownSubWrapperInner = styled.div`
  ${({ theme: { media } }) => css`
    @media (min-width: ${media.lg}) {
      position: relative;
      width: 710px;
      display: flex;
      justify-content: space-between;
    }
  `}
`;

const StyledUpSellBlockMedia = styled.div`
  ${({ theme: { space } }) => css`
    margin-bottom: ${space.md};
  `}
`;

const StyledUpSellBlockCopy = styled.div`
  ${({ theme: {} }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `}
`;

const StyledUpSellBlockSubHeading = styled(Typography)`
  ${({ theme: { space } }) => css`
    margin-bottom: ${space.xs};
  `}
`;

const StyledUpSellBlockHeading = styled(Typography)`
  ${({ theme: { space } }) => css`
    margin-bottom: ${space.md};
    white-space: normal;
    text-align: left;
  `}
`;

const StyledUpSellBlockBody = styled(HtmlContent)`
  ${({ theme: { space } }) => css`
    margin-bottom: ${space.md};
  `};
`;

const StyledUpSellBlockLinkText = styled(TextLink)`
  ${({ theme: {colors} }) => css`
    text-decoration: none;

    &:focus {
        outline: 2px dashed ${colors.white.default};
    }
  `};
`;

const StyledDropDownBox = styled.div<StyledDropDownBoxProps>`
  ${({
    theme: { maxMedia, borderRadii, layers },
    height,
    width,
    top,
    left,
  }) => css`
    background-color: #fff;
    border-radius: ${borderRadii.base};
    transition: all ease 0.25s;
    width: ${width}px;
    height: ${height}px;
    top: ${top}px;
    left: ${left}px;
    z-index: ${layers.dropDownBox};
    position: fixed;
    @media (max-width: ${maxMedia.lg}) {
      display: none;
    }
  `};
`;

const StyledSkipLink = styled.a`
  ${({ theme: { colors, space } }) => css`
    position: absolute;
    top: -150px;
    left: 0;
    background: #000;
    color: white;
    padding: 8px;
    z-index: 100;

    &:focus {
      top: 0;
    }
  `}
`;

const IntroContent: FC<IntroContentProps> = ({ item }) => {
  const [heading, body] = item.split('\r\n');
  return (
    <StyledIntroContent>
      {heading && (
        <StyledIntroContentHeading component="h4" variant="h4">
          
            {heading}
        
        </StyledIntroContentHeading>
      )}
      {body && (
        <StyledIntroContentBody component="p" variant="p">
          {body}
        </StyledIntroContentBody>
      )}
      
    </StyledIntroContent>
  );
};

const getColorByClass = (cssClass: string | undefined): { bgColor: string; textColor: string } => {
  switch (cssClass) {
    case 'red':
      return { bgColor: '#BA1C2C', textColor: '#fff' };
    case 'blue':
      return { bgColor: '#0099CC', textColor: '#fff' };
    case 'grey':
      return { bgColor: '#eeeeee', textColor: '#111' };
    case 'green':
      return { bgColor: '#008044', textColor: '#fff' };
    case 'orange':
      return { bgColor: '#ff6600', textColor: '#fff' };
    default:
      return { bgColor: '#fff', textColor: '#111' };
  }
};

const UpSellBlock: FC<UpSellBlockProps> = ({ item }) => {
  const imgSrc = getImage(item?.mediaItem?.localFile);
  const { bgColor, textColor } = getColorByClass(item?.cssClass);
  const backgroundColor = bgColor || item?.bgrColor || 'transparent';
  const textColorFinal = textColor || item?.txtColor || '#111';
  return (
    <StyledUpSellBlock $bgrColor={backgroundColor} $txtColor={textColorFinal}>
      {imgSrc && (
        <StyledUpSellBlockMedia>
          <GatsbyImage
            image={imgSrc}
            alt={item?.mediaItem?.alternativeText || ''}
            backgroundColor='transparent'
          />
        </StyledUpSellBlockMedia>
      )}
      <StyledUpSellBlockCopy>
        {item.title && (
          <StyledUpSellBlockSubHeading component="p" variant="caps">
            {item.title}
          </StyledUpSellBlockSubHeading>
        )}
        {item.content && (
          <StyledUpSellBlockHeading component="h3" variant="h3">
            {item.content}
          </StyledUpSellBlockHeading>
        )}
        {/* {item.content && <StyledUpSellBlockBody>{item.content}</StyledUpSellBlockBody>} */}
        {item.url && <StyledUpSellBlockLinkText url={item.url} text={item.linkText} />}
      </StyledUpSellBlockCopy>
    </StyledUpSellBlock>
  );
};

const SubNavItems: FC<SubNavItemsProps & { toggleMobileMenu: () => void }> = ({ 
  item, 
  setSubItemUpsellBlockActive, 
  shouldFocus, 
  parentUrl, 
  toggleMobileMenu
}) => {
    const subSubItems = item?.subSubItems;
    const hasSubDropdown = !!(subSubItems && subSubItems.length > 0);
    const upSellBlock = item?.upSellBlock;
    const [menuIsActive, setMenuIsActive] = useState(false);
    const [isSubMenuFocused, setIsSubMenuFocused] = useState(false);
    const [subUpSellBlockIsActive, setSubUpSellBlockIsActive] = useState(false);
    const subMenuRef = useRef<HTMLUListElement>(null);
    const dropdownRef = useRef<HTMLUListElement>(null); 

    const isMobile = useIsMobile(991);

    const handleSubNavToggle = () => {
      setMenuIsActive((prev) => !prev);
    };
  
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          handleSubNavToggle();
        } else if (e.key === 'Escape' && menuIsActive) {
          setMenuIsActive(false);
        } else if ((e.key === 'ArrowDown' || e.key === 'ArrowUp') && menuIsActive) {
          e.preventDefault();
          const direction = e.key === 'ArrowDown' ? 1 : -1;
          focusNextMenuItem(direction);
        } else if (e.key === 'Tab' && !menuIsActive) {
          handleSubNavToggle();
        }
    };
  
    const focusNextMenuItem = (direction: number) => {
      const menuItems = dropdownRef.current?.querySelectorAll('a, button');
      if (menuItems) {
        const currentIndex = Array.from(menuItems).indexOf(
          document.activeElement as HTMLElement
        );
        const nextIndex = (currentIndex + direction + menuItems.length) % menuItems.length;
        (menuItems[nextIndex] as HTMLElement).focus();
      }
    };
  
    const handleMouseEnter = () => {
      if (hasSubDropdown && window.innerWidth > 991) {
        setMenuIsActive(true);
        setSubItemUpsellBlockActive(true);
      }
      if (hasSubDropdown && window.innerWidth > 1224) {
        setSubUpSellBlockIsActive(true);
      }
    };
  
    const handleMouseExit = () => {
      setTimeout(() => {
        if (hasSubDropdown && window.innerWidth > 991) {
          setMenuIsActive(false);
        }
      }, 300);
  
      setSubItemUpsellBlockActive(false);
    };

    const handleFocus = () => {
        if (hasSubDropdown) {
            setMenuIsActive(true);
            setSubItemUpsellBlockActive(true);
            if (window.innerWidth > 1224) {
                setSubUpSellBlockIsActive(true);
            }
        }
    };

    const handleBlur = (e: React.FocusEvent) => {
        // Use setTimeout to allow the next element to receive focus before we check
        setTimeout(() => {
            if (!subMenuRef.current?.contains(document.activeElement)) {
                setMenuIsActive(false);
                setSubItemUpsellBlockActive(false);
                setSubUpSellBlockIsActive(false);
            }
        }, 0);
    };

    const handleSubWrapperFocus = () => {
        setIsSubMenuFocused(true);
        setMenuIsActive(true);
        setSubItemUpsellBlockActive(true);
        if (window.innerWidth > 1224) {
            setSubUpSellBlockIsActive(true);
        }
    };

    const handleSubWrapperBlur = (e: React.FocusEvent) => {
        // Use setTimeout to allow the next element to receive focus before we check
        setTimeout(() => {
            if (!subMenuRef.current?.contains(document.activeElement)) {
                setIsSubMenuFocused(false);
                setMenuIsActive(false);
                setSubItemUpsellBlockActive(false);
                setSubUpSellBlockIsActive(false);
            }
        }, 0);
    };
  
    useEffect(() => {
      if (menuIsActive) {
        const firstFocusableElement = dropdownRef.current?.querySelector('a, button, [tabindex]:not([tabindex="-1"])');
        if (firstFocusableElement) {
          (firstFocusableElement as HTMLElement).focus();
        }
      }
    }, [menuIsActive]);

    useEffect(() => {
      if (shouldFocus) {
        setMenuIsActive(true);
        setSubItemUpsellBlockActive(false);
      }
    }, [shouldFocus]);

    const handleNavItemSubLabelClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (hasSubDropdown) {
        e.preventDefault();
        setMenuIsActive((active) => !active);
      } 
      
      if (isMobile) {
        // Close the dropdown when a link is tapped on mobile
        toggleMobileMenu();
      }
    };
  
    return (
        <StyledNavItemSubLi onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseExit}>
          <StyledNavItemSubLabel
            to={item.url}
            activeClassName="is-active"
            onClick={handleNavItemSubLabelClick}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
            tabIndex={0}
            role={hasSubDropdown ? 'button' : undefined}
            aria-haspopup={hasSubDropdown ? 'true' : undefined}
            aria-expanded={menuIsActive ? 'true' : 'false'}
          >
            {item.title}
            {subSubItems && <StyledNavItemArrow size="sm" aria-hidden="true" />}
          </StyledNavItemSubLabel>
    
          {subSubItems && (
            <StyledDropdownSubWrapper
              ref={subMenuRef}
              $dropdownIsActive={hasSubDropdown && (menuIsActive || isSubMenuFocused)}
              aria-expanded={(menuIsActive || isSubMenuFocused) ? 'true' : 'false'}
              aria-controls={`dropdown-${item.id}`}
              aria-current={(menuIsActive || isSubMenuFocused) ? 'page' : undefined}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  setMenuIsActive(false);
                  setIsSubMenuFocused(false);
                }
              }}
              onFocus={handleSubWrapperFocus}
              onBlur={handleSubWrapperBlur}
              role="menu"
            >
              <StyledDropdownSubWrapperInner>
                <StyledBackButton
                  aria-expanded={(menuIsActive || isSubMenuFocused) ? 'true' : 'false'}
                  onClick={() => {
                    setMenuIsActive((prev) => !prev);
                    setIsSubMenuFocused(false);
                  }}
                >
                  <ArrowLeft size="sm" /> Back
                </StyledBackButton>
                <StyledSubSubNavItemsUL
                  $dropdownIsActive={hasSubDropdown && (menuIsActive || isSubMenuFocused)}
                  onFocus={() => setIsSubMenuFocused(true)}
                  onBlur={(e) => {
                    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
                      setIsSubMenuFocused(false);
                    }
                  }}
                >
                  {subSubItems.map((subSubItem, index) => (
                    <StyledNavItemSubLi key={index} role="none">
                      <StyledNavItemLabel
                        to={subSubItem.url}
                        $dropdownIsActive
                        activeClassName="is-active"
                        role="menuitem"
                      >
                        {subSubItem.label}
                      </StyledNavItemLabel>
                    </StyledNavItemSubLi>
                  ))}
                </StyledSubSubNavItemsUL>
                {upSellBlock && subUpSellBlockIsActive && <UpSellBlock item={upSellBlock} />}
              </StyledDropdownSubWrapperInner>
            </StyledDropdownSubWrapper>
          )}
        </StyledNavItemSubLi>
      );
  };

  const NavItem: FC<NavItemProps & { isActive: boolean; onActivate: () => void; onDeactivate: () => void; forceHover?: boolean; toggleMobileMenu: () => void; }> = ({
    item,
    setDropdownActive,
    setDropdownDimension,
    setNavItemLabelDimension,
    isActive,
    onActivate,
    onDeactivate,
    forceHover = false,
    toggleMobileMenu,
  }) => {
    const dropDownEl = useRef<HTMLDivElement>(null);
    const navItemLabelEl = useRef<HTMLAnchorElement>(null);
    const subItems = item?.childMenuItems?.filter(subItem => subItem.menuItemBlockDisplay !== "Featured block");
    const numberColumns = '2';
    const url = item?.url;
    const label = item?.title;
    const introContent = item?.description;
    const upSellBlock = item?.childMenuItems?.find(subItem => subItem.menuItemBlockDisplay === "Featured block");
    const hasDropdown = !!(subItems && subItems.length > 0);
    const isDropdownToggle = hasDropdown && !url;
    const [menuIsActive, setMenuIsActive] = useState(false);
    const [subItemUpsellBlockActive, setSubItemUpsellBlockActive] = useState(false);
    const [focusedSubItemIndex, setFocusedSubItemIndex] = useState<number | null>(null);

    const isMobile = useIsMobile(991);

    const handleMouseEnter = useCallback(() => {
      if (hasDropdown && !isMobile) {
        setDropdownActive(true);
        setMenuIsActive(true);
        onActivate();
      }
    }, [hasDropdown, onActivate]);
  
    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (!hasDropdown) return;
    
      if (e.key === 'Enter' || e.key === ' ' || (!menuIsActive && e.key === 'Tab')) {
        e.preventDefault();
        setMenuIsActive(true);
        setDropdownActive(true);
        onActivate();
        setFocusedSubItemIndex(0);
        // Focus the first item in the dropdown
        setTimeout(() => {
          const firstFocusableElement = dropDownEl.current?.querySelector('a, button, [tabindex]:not([tabindex="-1"])');
          if (firstFocusableElement) {
            (firstFocusableElement as HTMLElement).focus();
          }
        }, 0);
      } else if (e.key === 'Escape' && menuIsActive) {
        setMenuIsActive(false);
        setDropdownActive(false);
        navItemLabelEl.current?.focus();
        onDeactivate();
      } else if (menuIsActive && (e.key === 'ArrowDown' || e.key === 'ArrowUp')) {
        e.preventDefault();
        const direction = e.key === 'ArrowDown' ? 1 : -1;
        focusNextMenuItem(direction);
      }
    };
  
    const focusNextMenuItem = (direction: number) => {
      const menuItems = dropDownEl.current?.querySelectorAll('a, button, [tabindex]:not([tabindex="-1"])');
      if (menuItems) {
        const currentIndex = Array.from(menuItems).indexOf(document.activeElement as HTMLElement);
        const nextIndex = (currentIndex + direction + menuItems.length) % menuItems.length;
        (menuItems[nextIndex] as HTMLElement).focus();
        setFocusedSubItemIndex(nextIndex);
      }
    };
  
    const handleBlur = (e: React.FocusEvent) => {
      // Close the dropdown if focus moves outside of it
      if (dropDownEl.current && !dropDownEl.current.contains(e.relatedTarget as Node)) {
        setMenuIsActive(false);
        setDropdownActive(false);
        onDeactivate();
      }
    };
  
    const handleMouseExit = useCallback(() => {
      if (hasDropdown && !isMobile) {
        setDropdownActive(false);
        setMenuIsActive(false);
        setFocusedSubItemIndex(null);
        onDeactivate();
      }
    }, [hasDropdown, onDeactivate]);

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (hasDropdown && isMobile) { // Prevent default only on mobile
        e.preventDefault();
        setMenuIsActive((active) => !active);
        if (!menuIsActive) {
          onActivate();
        } else {
          onDeactivate();
        }
      }

      if (!hasDropdown && isMobile) {
        // Close the dropdown when a link is tapped on mobile
        toggleMobileMenu();
      }
    };
  
    useEffect(() => {
      // console.log('Effect triggered, menuIsActive:', menuIsActive);
      if (dropDownEl.current && menuIsActive) {
        const dropdownRect = dropDownEl.current.getBoundingClientRect();
        const labelRect = navItemLabelEl.current?.getBoundingClientRect();
        // console.log('Setting dimensions:', { dropdownRect, labelRect });
        setDropdownDimension(dropdownRect);
        setNavItemLabelDimension(labelRect);
      }
    }, [menuIsActive, setDropdownDimension, setNavItemLabelDimension]);
  
    useEffect(() => {
      if (!isActive) {
        setMenuIsActive(false);
      }
    }, [isActive]);

    useEffect(() => {
      if (forceHover) {
        handleMouseEnter();
      }
    }, [forceHover, handleMouseEnter]);
  
    return (
      <StyledNavItemLi
        onMouseEnter={handleMouseEnter}
        onMouseLeave={forceHover ? undefined : handleMouseExit}
        $dropdownWidth={numberColumns}
      >
        {isDropdownToggle ? (
          <StyledNavItemButton
            $dropdownIsActive={menuIsActive}
            ref={navItemLabelEl}
            // onClick={() => {
            //   setMenuIsActive((active) => !active);
            //   if (!menuIsActive) {
            //     onActivate();
            //   } else {
            //     onDeactivate();
            //   }
            // }}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            aria-haspopup="true"
            aria-expanded={menuIsActive ? 'true' : 'false'}
            aria-controls={`dropdown-${item.id}`}
            aria-current={menuIsActive ? 'page' : undefined}
          >
            {label}
            <StyledNavItemArrow
              size="sm"
              aria-hidden="true"
              aria-label={`Expand ${label} menu`}
            />
          </StyledNavItemButton>
        ) : (
          <StyledNavItemLabel
            to={url}
            activeClassName="is-active"
            $dropdownIsActive={menuIsActive}
            ref={navItemLabelEl}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            tabIndex={0}
            role={hasDropdown ? 'button' : undefined}
            aria-haspopup={hasDropdown ? 'true' : undefined}
            aria-expanded={menuIsActive ? 'true' : 'false'}
            aria-controls={hasDropdown ? `dropdown-${item.id}` : undefined}
            aria-current={menuIsActive ? 'page' : undefined}
          >
            {label}
            {hasDropdown && (
              <StyledNavItemArrow
                size="sm"
                aria-hidden="true"
                aria-label={`Expand ${label} menu`}
              />
            )}
          </StyledNavItemLabel>
        )}
        <StyledDropdownWrapper
          ref={dropDownEl}
          $dropdownWidth={numberColumns}
          $dropdownIsActive={hasDropdown && menuIsActive}
          id={`dropdown-${item.id}`}
          role="menu"
          tabIndex={-1}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              setMenuIsActive(false);
              setDropdownActive(false);
              navItemLabelEl.current?.focus();
              onDeactivate();
            }
          }}
        >
          <StyledDropdownWrapperInner>
            <StyledBackButton
              aria-expanded={menuIsActive ? 'true' : 'false'}
              onClick={() => {
                setMenuIsActive(false);
                setDropdownActive(false);
                onDeactivate();
              }}
            >
              <ArrowLeft size="sm" /> Back
            </StyledBackButton>
  
            <StyledDropdown $dropdownIsActive={menuIsActive}>
              {subItems?.map((subItem, index) => (
                <SubNavItems
                  item={subItem}
                  parentUrl={url}
                  setSubItemUpsellBlockActive={setSubItemUpsellBlockActive}
                  key={subItem.id || index}
                  shouldFocus={focusedSubItemIndex === index}
                  toggleMobileMenu={toggleMobileMenu}
                />
              ))}
              {introContent && <IntroContent item={introContent} />}
            </StyledDropdown>
            {upSellBlock && !subItemUpsellBlockActive && <UpSellBlock item={upSellBlock} />}
          </StyledDropdownWrapperInner>
        </StyledDropdownWrapper>
      </StyledNavItemLi>
    );
  };  
  export const Nav: FC<NavProps> = ({ items, onDropdownActiveChange, debugForceHoverFirstItem = false,  toggleMobileMenu, ...rest }) => {
    const navRef = useRef(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [dropdownActive, setDropdownActive] = useState(false);
    const [dropdownDimension, setDropdownDimension] = useState<any>();
    const [navItemLabelDimension, setNavItemLabelDimension] = useState<DOMRect | undefined>(undefined);
    const [activeItemIndex, setActiveItemIndex] = useState<number | null>(null);
    const [arrowLeftPos, setArrowLeftPos] = useState(0);

    // useEffect(() => {
      // console.log('navItemLabelDimension updated:', navItemLabelDimension);
    // }, [navItemLabelDimension]);

    useEffect(() => {
      if (onDropdownActiveChange) {
        onDropdownActiveChange(dropdownActive);
      }
    }, [dropdownActive, onDropdownActiveChange]);
  
    // useEffect(() => {
    //   if (isBrowser) {
    //     const { body } = document;
    //     const scrollbarWidth = window.innerWidth - body.clientWidth;
    //     body.style.paddingRight = dropdownActive ? `${scrollbarWidth}px` : '0px';
    //     body.classList.toggle('is-hidden', dropdownActive);
    //   }
    // }, [dropdownActive]);
  
    useEffect(() => {
      if (!navRef.current) return;
  
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Tab' && dropdownActive) {
          const focusableElements = navRef.current!.querySelectorAll<HTMLElement>('a, button, [tabindex]:not([tabindex="-1"])');
          const firstElement = focusableElements[0];
          const lastElement = focusableElements[focusableElements.length - 1];
          if (e.shiftKey && document.activeElement === firstElement) {
            e.preventDefault();
            lastElement.focus();
          } else if (!e.shiftKey && document.activeElement === lastElement) {
            e.preventDefault();
            firstElement.focus();
          }
        }
      };
  
      const handleFocusOut = (e: FocusEvent) => {
        if (!navRef.current?.contains(e.relatedTarget as Node)) {
          setDropdownActive(false);
          setActiveItemIndex(null);
        }
      };
  
      navRef.current.addEventListener('keydown', handleKeyDown);
      navRef.current.addEventListener('focusout', handleFocusOut);

      return () => {
        navRef.current?.removeEventListener('keydown', handleKeyDown);
        navRef.current?.removeEventListener('focusout', handleFocusOut);
      };
    }, [dropdownActive]);

    // console.log('navItemLabelDimension', navItemLabelDimension);

    useEffect(() => {
      if (navItemLabelDimension && containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const arrowLeftPos =
          navItemLabelDimension.left +
          navItemLabelDimension.width / 2 -
          containerRect.left -
          6;
  
        setArrowLeftPos(arrowLeftPos);
      } else {
        setArrowLeftPos(0);
      }
    }, [navItemLabelDimension]);
  
    return (
      <>
        <StyledSkipLink href="#main-content">Skip to main content</StyledSkipLink>
        <StyledNav
          ref={navRef}
          arrowLeftPos={arrowLeftPos}
          dropdownActive={dropdownActive}
          {...rest}
        >
          <StyledNavContainer ref={containerRef}>
            {items && (
              <StyledNavItems>
                {items.map((item, index) => (
                  <NavItem
                    item={item}
                    key={index}
                    setDropdownActive={setDropdownActive}
                    setDropdownDimension={setDropdownDimension}
                    setNavItemLabelDimension={setNavItemLabelDimension}
                    isActive={activeItemIndex === index}
                    onActivate={() => setActiveItemIndex(index)}
                    onDeactivate={() => setActiveItemIndex(null)}
                    forceHover={debugForceHoverFirstItem && index === 0}
                    toggleMobileMenu={toggleMobileMenu}
                  />
                ))}
              </StyledNavItems>
            )}
          </StyledNavContainer>
        </StyledNav>
        {dropdownActive && (
          <StyledDropDownBox
            height={dropdownDimension?.height}
            width={dropdownDimension?.width}
            top={dropdownDimension?.top}
            left={dropdownDimension?.left}
          />
        )}
      </>
    );
  };