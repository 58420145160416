import React from 'react';
import styled, { css } from 'styled-components';
import { Hatch_ContentMenuItemType } from '../graphqlTypes';
import { verticalContainers, spaceItems, unstyledList } from '../utils';
import NewsletterSignupCta from './NewsletterSignupCta';
import { SocialPassive } from './SocialPassive';
import { LinkTo } from './LinkTo';
import { Typography } from './Typography';
import { Container } from './Container';
import { FeLogoFooter } from './icons/FeLogoFooter';

interface FooterProps {
    menu: [Hatch_ContentMenuItemType];
    pathname: string;
}

const StyledFooter = styled.footer`
    ${({ theme: { colors, media, space } }) => css`
        padding: ${space.xxl} 0;
        background-color: ${colors.black.default};
        color: ${colors.white.default};

        @media (min-width: ${media.md}) {
            padding: ${space.xxxl} 0;
        }
    `}
`;

const StyledFooterColumn = styled.div`
    ${({ theme: { media } }) => css``}
`;

const StyledFooterContent = styled.div`
    ${({ theme: { media, space, maxMedia } }) => css`
        height: 100%;
        @media (max-width: ${maxMedia.lg}) {
            > * + * {
                margin-top: ${space.md};
            }
        }
        @media (min-width: ${media.lg}) {
            display: flex;
            justify-content: space-between;
        }
    `}
`;

const StyledFooterUl = styled(Typography)`
    ${({ theme: { media, space } }) => css`
        ${unstyledList()}; // Remove default list styling
        columns: 1;

        > * + * {
            margin-top: ${space.xs};
        }

        // Desktop
        @media (min-width: ${media.md}) {
            columns: 2;
            text-align: left;
        }
    `}
`;

const StyledFooterUlLink = styled(LinkTo)`
    ${({ theme: { colors } }) => css`
        // Overwrite the default link colour when links are used within a Footer
        color: ${colors.white.default};
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    `}
`;

const StyledFooterFootNote = styled(Typography)`
    ${({ theme: { colors, maxMedia, space } }) => css`
        color: ${colors.grey1.default};
        @media (max-width: ${maxMedia.lg}) {
            padding-top: ${space.md};
        }
    `}
`;

const StyledFooterFootNoteBrXs = styled.span`
    ${({ theme: { maxMedia } }) => css`
        @media (max-width: ${maxMedia.lg}) {
            display: block;
        }
    `}
`;

const StyledFooterFootNoteBrLg = styled.span`
    ${({ theme: { media } }) => css`
        @media (min-width: ${media.lg}) {
            display: block;
        }
    `}
`;

const StyledFooterSocialContainer = styled.div`
    ${({ theme: { media, space } }) => css`
        display: flex;
        flex-flow: column wrap;
        padding-top: ${space.xxl};

        @media (min-width: ${media.lg}) {
            justify-content: space-between;
            flex-flow: row-reverse nowrap;
        }
    `}
`;

export const Footer = ({ menu, pathname }: FooterProps) => (
    <StyledFooter role="contentinfo">
        <Container>
            <StyledFooterContent>
                <StyledFooterColumn>
                    <FeLogoFooter />
                </StyledFooterColumn>
                <StyledFooterColumn>
                    <StyledFooterUl component="ul" variant="body">
                        {menu?.length >= 1 &&
                            menu.map((item, i) => (
                                <li key={`footer-menu-${i}`}>
                                    <StyledFooterUlLink to={item.url}>
                                        {item.title}
                                    </StyledFooterUlLink>
                                </li>
                            ))}
                    </StyledFooterUl>
                </StyledFooterColumn>
                <StyledFooterColumn>
                    <NewsletterSignupCta pathname={pathname} />
                </StyledFooterColumn>
            </StyledFooterContent>

            <StyledFooterSocialContainer>
                <SocialPassive />
                <StyledFooterFootNote component="p" variant="small">
                    &copy; Copyright {new Date().getFullYear()} Fresh Egg
                    Limited. All Rights Reserved.
                    <StyledFooterFootNoteBrLg>
                        <StyledFooterFootNoteBrXs>
                            VAT number: 750 4915 34
                        </StyledFooterFootNoteBrXs>{' '}
                        <StyledFooterFootNoteBrXs>
                            Company registration number: 3950929
                        </StyledFooterFootNoteBrXs>
                    </StyledFooterFootNoteBrLg>
                </StyledFooterFootNote>
            </StyledFooterSocialContainer>
        </Container>
    </StyledFooter>
);

export default Footer;
