import React, { FC } from 'react';
import { Icon, IconProps } from './Icon';

export const SocialTwitter: FC<IconProps> = (props) => (
    <Icon {...props}>
        <path
            fill="currentColor"
            d="M6 10c0-2.206 1.794-4 4-4h28c2.206 0 4 1.794 4 4v28c0 2.206-1.794 4-4 4H10c-2.206 0-4-1.794-4-4V10Zm32 0H10v28h28.004L38 10Zm-4.293 4-7.375 8.432L35 33.898h-6.79l-5.323-6.952-6.094 6.952h-3.37l7.897-9.016L13 14h6.964l4.8 6.355L30.325 14h3.383Zm-14.775 1.915H16.93l12.2 15.956h1.878L18.932 15.915Z"
        />
    </Icon>
);
