import React, { FC } from 'react';
import { Icon, IconProps } from './Icon';

export const ArrowDownExpand: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M14.8594 19L11.8594 22L24 34.1406L36.1406 22L33.1406 19L24 28.1406L14.8594 19Z" fill="currentColor"/>
  </Icon>
);
















