import { ThemeBordersKeys } from './borders';
import { ThemeBorderRadiiKeys } from './borderRadii';
import { ThemeColorsKeys } from './colors';
import { ThemeFontsKeys } from './typography';

const buttons = {
  solid: {
    type: 'caps',
    border: 'black',
    background: 'black',
    color: 'white',
    borderRadius: 'buttons',
    hover: {
      border: 'accent2',
      background: 'accent2',
    },
    disabled: {
      border: 'white',
      background: 'grey1',
    },
  },
  outline: {
    type: 'caps',
    border: 'black',
    background: 'transparent',
    color: 'black',
    borderRadius: 'buttons',
    hover: {
      color: 'white',
      border: 'accent2',
      background: 'accent2',
    },
    disabled: {
      border: 'grey1',
      background: 'grey2',
    },
  },
  outlineReverse: {
    type: 'caps',
    border: 'white',
    background: 'transparent',
    color: 'white',
    borderRadius: 'buttons',
    hover: {
      color: 'white',
      border: 'accent2',
      background: 'accent2',
    },
    disabled: {
      border: 'grey1',
      background: 'grey2',
    },
  },

};

export default buttons;

interface ThemeButtonBase {
  color: ThemeColorsKeys;
  background: ThemeColorsKeys;
  border: ThemeBordersKeys;
  borderRadius: ThemeBorderRadiiKeys;
  underline?: boolean;
}

interface ThemeButton extends ThemeButtonBase {
  type: ThemeFontsKeys;
  hover: ThemeButtonBase;
  disabled: ThemeButtonBase;
  selected?: ThemeButtonBase;
}
export type ThemeButtonKeys = keyof typeof buttons;
export type ThemeButtons = Record<ThemeButtonKeys, ThemeButton>;