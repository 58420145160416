export const space = {
    none: '0',
    xs: '8px',
    sm: '16px',
    md: '24px',
    lg: '32px',
    xl: '48px',
    xxl: '64px',
    xxxl: '96px',
};

export default space;
export type ThemeSpace = typeof space;
export type ThemeSpaceKeys = keyof typeof space;
