const layers = {
    subZero: -1,
    sub: 0,
    base: 1,
    sup: 2,
    checkbox: 3,
    checkboxActive: 4,
    editAction: 5,
    comparisonTable: 6,
    comparisonTableHead: 7,
    bgOverlay: 8,
    dropDownSm: 9,
    dropDownBox: 9,
    header: 10,
    dropDown: 11,
    modal: 12,
    modalBackdrop: 13,
    modalContent: 14,
    modalClose: 15,
    adminBar: 16,
};

export default layers;

export type ThemeLayers = typeof layers;
