import { css } from 'styled-components';

export const snapPoints = {
    sm: '540px',
    md: '720px',
    lg: '960px',
    xl: '1140px',
};


export const maxWidths = (points, media) => {
    if (!points) {
        return;
    }
    const mediaQs = Object.entries(points)
        .map(
            ([key, value]) => `
              @media(min-width: ${media[key]}) {
                  max-width: ${value};
              }
          `
        )
        .join('');

    return css`
        ${mediaQs}
    `;
};
