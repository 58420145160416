import { isBrowser } from '../utils/isBrowser';

interface useDataLayerPushReturn {
    dataLayerPush: any;
}

export const useDataLayerPush = (): useDataLayerPushReturn => {
    const dataLayerPush = (data) => {
        if (isBrowser && window.dataLayer) {
            window.dataLayer.push({
                ...data,
            });
        }
    };

    return {
        dataLayerPush,
    };
};
