const shadows = {
    default:
        '0 4px 24px -4px rgba(17, 17, 17, 0.08), 0 2px 8px -2px rgba(0, 0, 0, 0.04)',
    hover: '0 18px 64px -8px rgba(17, 17, 17, 0.15), 0 4px 24px -3px rgba(17, 17, 17, 0.16)',
};

export default shadows;

export type ThemeShadows = typeof shadows;
export type ThemeShadowsKeys = keyof typeof shadows;
