import { ThemeMediaKeys } from './media';

const fontFamily = {
    base: `'Poppins', 'Poppins Regular Fallback', sans-serif`,
    bold: `'Poppins', 'Poppins Bold Fallback', sans-serif`,
};

export const fontWeight = {
    regular: 400,
    bold: 700,
};

export interface TypeStyles {
    fontFamily?: string;
    fontWeight?: number;
    fontSize?: string;
    lineHeight?: string;
    textTransform?: string;
    letterSpacing?: string;
    breakpoints?: [TypeBreakpoints];
}

interface TypeBreakpoints extends TypeStyles {
    breakpointSize: ThemeMediaKeys;
}
export interface TypeVariants {
    default: TypeStyles;
}
export type TypeVariantsOptions = keyof TypeVariants;

export type ThemeFonts = Record<string, TypeVariants>;

const defaultBreakpointSize = 'md';

const type: ThemeFonts = {
    h1: {
        default: {
            fontWeight: fontWeight.bold,
            fontFamily: fontFamily.bold,
            fontSize: '32px',
            lineHeight: '40px',
            letterSpacing: '-0.64px',
            breakpoints: [
                {
                    breakpointSize: defaultBreakpointSize,
                    fontSize: '56px',
                    lineHeight: '64px',
                    letterSpacing: '-1.12px',
                },
            ],
        },
    },
    h2: {
        default: {
            fontWeight: fontWeight.bold,
            fontFamily: fontFamily.bold,
            fontSize: '26px',
            lineHeight: '32px',
            breakpoints: [
                {
                    breakpointSize: defaultBreakpointSize,
                    fontSize: '38px',
                    lineHeight: '48px',
                },
            ],
        },
    },
    h3: {
        default: {
            fontWeight: fontWeight.bold,
            fontFamily: fontFamily.bold,
            fontSize: '20px',
            lineHeight: '24px',
            breakpoints: [
                {
                    breakpointSize: defaultBreakpointSize,
                    fontSize: '22px',
                    lineHeight: '32px',
                },
            ],
        },
    },
    h4: {
        default: {
            fontWeight: fontWeight.bold,
            fontFamily: fontFamily.bold,
            fontSize: '16px',
            lineHeight: '24px',
            breakpoints: [
                {
                    breakpointSize: defaultBreakpointSize,
                    fontSize: '16px',
                    lineHeight: '24px',
                },
            ],
        },
    },
    h5: {
        default: {
            fontWeight: fontWeight.bold,
            fontFamily: fontFamily.bold,
            fontSize: '16px',
            lineHeight: '24px',
            breakpoints: [
                {
                    breakpointSize: defaultBreakpointSize,
                    fontSize: '16px',
                    lineHeight: '24px',
                },
            ],
        },
    },
    lead: {
        default: {
            fontWeight: fontWeight.regular,
            fontFamily: fontFamily.base,
            fontSize: '16px',
            lineHeight: '24px',
            breakpoints: [
                {
                    breakpointSize: defaultBreakpointSize,
                    fontSize: '22px',
                    lineHeight: '32px',
                },
            ],
        },
    },
    body: {
        default: {
            fontWeight: fontWeight.regular,
            fontFamily: fontFamily.base,
            fontSize: '14px',
            lineHeight: '24px',
            breakpoints: [
                {
                    breakpointSize: defaultBreakpointSize,
                    fontSize: '14px',
                    lineHeight: '24px',
                },
            ],
        },
    },
    bodyBold: {
        default: {
            fontWeight: fontWeight.bold,
            fontFamily: fontFamily.bold,
            fontSize: '14px',
            lineHeight: '24px',
            breakpoints: [
                {
                    breakpointSize: defaultBreakpointSize,
                    fontSize: '14px',
                    lineHeight: '24px',
                },
            ],
        },
    },
    caps: {
        default: {
            fontWeight: fontWeight.bold,
            fontFamily: fontFamily.bold,
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '0.48px',
            textTransform: 'uppercase',
            breakpoints: [
                {
                    breakpointSize: defaultBreakpointSize,
                    fontSize: '14px',
                    lineHeight: '24px',
                    letterSpacing: '0.28px',
                },
            ],
        },
    },
    small: {
        default: {
            fontWeight: fontWeight.regular,
            fontFamily: fontFamily.base,
            fontSize: '12px',
            lineHeight: '16px',
            breakpoints: [
                {
                    breakpointSize: defaultBreakpointSize,
                    fontSize: '12px',
                    lineHeight: '16px',
                },
            ],
        },
    },
};

export default type;

export type ThemeFontsKeys = keyof typeof type;
