import React from 'react';
import styled, { css } from 'styled-components';
import { unstyledList } from '../utils';
import {
    SocialFacebook,
    SocialInstagram,
    SocialLinkedin,
    SocialTwitter,
} from './icons';

const StyledSocialPassiveItems = styled.ul`
    ${unstyledList()};
    display: flex;
    flex-flow: row wrap;
`;

const StyledSocialPassiveItem = styled.li`
    ${({ theme: { space, colors } }) => css`
        margin: 0 ${space.xs} 0 0;
        padding: 0;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            svg {
                g,
                path {
                    fill: ${colors.white.default};
                }
            }
        }
    `}
`;

export const SocialPassive = () => (
    <nav className="social-passive">
        <StyledSocialPassiveItems>
            <StyledSocialPassiveItem>
                <a
                    href="https://www.facebook.com/FreshEggUK/"
                    target="_blank"
                    className="social-passive__link"
                    rel="noopener noreferrer"
                >
                    <span className="sr-only">Facebook</span>
                    <span className="social-passive__icon">
                        <SocialFacebook size="lg" />
                    </span>
                </a>
            </StyledSocialPassiveItem>
            <StyledSocialPassiveItem>
                <a
                    href="https://www.instagram.com/freshegguk/"
                    target="_blank"
                    className="social-passive__link"
                    rel="noopener noreferrer"
                >
                    <span className="sr-only">Instagram</span>
                    <span className="social-passive__icon">
                        <SocialInstagram size="lg" />
                    </span>
                </a>
            </StyledSocialPassiveItem>
            <StyledSocialPassiveItem>
                <a
                    href="https://twitter.com/freshegg"
                    target="_blank"
                    className="social-passive__link"
                    rel="noopener noreferrer"
                >
                    <span className="sr-only">Twitter</span>
                    <span className="social-passive__icon">
                        <SocialTwitter size="lg" />
                    </span>
                </a>
            </StyledSocialPassiveItem>
            <StyledSocialPassiveItem>
                <a
                    href="https://www.linkedin.com/company/fresh-egg"
                    target="_blank"
                    className="social-passive__link"
                    rel="noopener noreferrer"
                >
                    <span className="sr-only">LinkedIn</span>
                    <span className="social-passive__icon">
                        <SocialLinkedin size="lg" />
                    </span>
                </a>
            </StyledSocialPassiveItem>
        </StyledSocialPassiveItems>
    </nav>
);
