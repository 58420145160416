import React, { createContext, useEffect, useState } from 'react';
import { isBrowser } from '../utils/isBrowser';

export interface ExperimentsContextProps {
    experiments?: object;
    setExperiments: React.Dispatch<React.SetStateAction<object | undefined>>;
}

export const ExperimentsContext = createContext({} as ExperimentsContextProps);
ExperimentsContext.displayName = 'ExperimentsContext';

export const ExperimentsContextProvider = ({ children }) => {
    const [experiments, setExperiments] = useState<object>();
    const waitForGlobal = function (key, callback) {
        if (window[key]) {
            callback();
        } else {
            setTimeout(() => {
                waitForGlobal(key, callback);
            }, 100);
        }
    };

    useEffect(() => {
        if (isBrowser) {
            waitForGlobal('experiments', () => {
                setExperiments(window.experiments);
            });
        }
    }, []);

    const contextValues: ExperimentsContextProps = {
        experiments,
        setExperiments,
    };

    return (
        <ExperimentsContext.Provider value={contextValues}>
            {children}
        </ExperimentsContext.Provider>
    );
};
