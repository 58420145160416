import React, { FC } from 'react';

export const Mobile: FC = (props) => (
    <svg enableBackground="new 0 0 18 30" viewBox="0 0 18 30" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            clipRule="evenodd"
            d="m13.2 1h-8.4c-2.1 0-3.8 1.7-3.8 3.7v20.5c0 2.1 1.7 3.8 3.8 3.8h8.4c2.1 0 3.8-1.7 3.8-3.7v-20.6c0-2-1.7-3.7-3.8-3.7zm1.8 24.3c0 1-.8 1.7-1.8 1.7h-8.4c-1 0-1.8-.8-1.8-1.7v-20.6c0-.9.8-1.7 1.8-1.7h8.4c1 0 1.8.8 1.8 1.7zm-5-20.4h-2c-.5 0-1 .4-1 1s.4 1 1 1h2c.5 0 1-.4 1-1s-.5-1-1-1zm-1 17.1c-.8 0-1.4.7-1.4 1.5s.6 1.5 1.4 1.5 1.4-.7 1.4-1.5-.6-1.5-1.4-1.5z"
            fill="#c07"
            fillRule="evenodd"
        />
    </svg>
);
