import { colors } from './colors';

const borders = {
    black: `solid 2px ${colors.black.default}`,
    accent2: `solid 2px ${colors.accent2.default}`,
    white: `solid 2px ${colors.white.default}`,
};

export default borders;

export type ThemeBorders = typeof borders;
export type ThemeBordersKeys = keyof typeof borders;
