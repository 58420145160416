import { ThemeColorsKeys } from '../styles/tokens/colors';

export const mapCssOverlayToBackgroundColor = (cssOverlay: string): ThemeColorsKeys => {
    switch (cssOverlay) {
        case 'fe-green':
            return 'accent3';
        case 'fe-magenta':
            return 'accent2';
        case 'fe-cyan':
            return 'accent1';
        case 'fe-orange':
            return 'accent4';
        case 'basic':
        case 'default':
        default:
            return 'white';
    }
};