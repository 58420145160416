const animationSpeeds = {
    default: '0.5s',
    fast: '0.4s',
    faster: '0.2s',
    slow: '0.7s',
    slower: '0.8s',
    slowest: '2.5s',
};
type ThemeAnimationSpeeds = typeof animationSpeeds;
const animationTimings = {
    default: 'ease-in-out',
    elastic: 'cubic-bezier(0.75, 0.18, 0.19, 0.88)',
};

type ThemeAnimationTimings = typeof animationTimings;

const animations = {
    speed: {
        ...animationSpeeds,
    } as ThemeAnimationSpeeds,
    timing: {
        ...animationTimings,
    } as ThemeAnimationTimings,
};

export default animations;

export type ThemeAnimation = typeof animations;
export type ThemeAnimationKeys = keyof typeof animations;
